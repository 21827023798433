import { lazy, Suspense } from 'react';
import { isLogSearchV2FeatureEnabled } from 'src/utils/featureFlags';

const LogSearch = lazy(() => import(/* webpackPrefetch: true */ 'src/pages/LogSearch/LogSearch'));
const LogSearchV2 = lazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/LogSearch/LogSearchQuery'),
);

const LogSearchPage = () => (
  <Suspense>{isLogSearchV2FeatureEnabled() ? <LogSearchV2 /> : <LogSearch />}</Suspense>
);

export default LogSearchPage;

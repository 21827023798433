import { Filter } from '@common-types/common/v1/common';
import { Sink, Source, Transform } from '@common-types/site_manager/model/v1/node';
import startCase from 'lodash/startCase';
import { createFilterOption } from 'src/components/Filters/utils';
import { FILTER_VIEW_NAME_VS_DEFAULT_VALUES } from 'src/constants/filters';

export const getNodeFilterValues = <T extends Source | Sink | Transform>(nodes?: T[]) =>
  nodes?.map(({ id, name }) => createFilterOption(id, name)) ?? [];

export function doesStringsMatch(str1: string, str2: string): boolean {
  return startCase(str1)
    .toLowerCase()
    .replace(/ /g, '')
    .includes(startCase(str2).toLowerCase().replace(/ /g, ''));
}

export function purgeFiltersFromStorage() {
  Object.keys(FILTER_VIEW_NAME_VS_DEFAULT_VALUES).forEach((key) => {
    if (sessionStorage.getItem(key)) {
      sessionStorage.setItem(
        key,
        JSON.stringify(
          FILTER_VIEW_NAME_VS_DEFAULT_VALUES[
            key as keyof typeof FILTER_VIEW_NAME_VS_DEFAULT_VALUES
          ],
        ),
      );
    }
  });
}

export function removeEmptyFilters(filters: Filter[]) {
  return filters.filter(
    ({ stringValue, stringArray }) => stringValue || stringArray?.items?.length,
  );
}

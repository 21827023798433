import { isIgnoreErrorsFeatureEnabled } from './featureFlags';

export function isIgnorePipelineErrors() {
  return (
    localStorage.getItem('ignore_pipeline_errors') === 'true' || isIgnoreErrorsFeatureEnabled()
  );
}

export function parseErrorDescriptionAsJson(errorDescription: string) {
  try {
    const errorJson = JSON.parse(errorDescription);
    const errorTitle = errorJson['Error'];
    const errorCauses = errorJson['Possible causes'] as string[];
    const errorResolutions = errorJson['Resolutions'] as string[];

    return { errorTitle, errorCauses, errorResolutions };
  } catch {
    return { errorTitle: errorDescription, errorCauses: [], errorResolutions: [] };
  }
}

import {
  DateValue,
  getLocalTimeZone,
  toCalendarDateTime,
  DateFormatter,
  parseAbsoluteToLocal,
} from '@saas-ui/date-picker';
import { formatRelative } from 'date-fns/formatRelative';
import { formatDistance } from 'date-fns/formatDistance';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import capitalize from 'lodash/capitalize';
import { getBrowserLanguage } from './general';

export function getDefaultFormattedDateTime(
  input?: string | number,
  defaultValue?: string,
  options?: Intl.DateTimeFormatOptions,
): string {
  if (!input || !isValidDate(new Date(input))) {
    return defaultValue || '';
  }

  return new DateFormatter(getBrowserLanguage(), {
    timeZone: getLocalTimeZone(),
    dateStyle: 'medium',
    timeStyle: 'medium',
    localeMatcher: 'best fit',
    ...(options || {}),
  }).format(new Date(input));
}

export function getFormattedFullDateTime(input?: string | number, defaultValue?: string): string {
  return getDefaultFormattedDateTime(input, defaultValue, { dateStyle: 'short' });
}

export function getFormattedDate(input?: string | number, defaultValue?: string): string {
  return getDefaultFormattedDateTime(input, defaultValue, {
    dateStyle: 'short',
    timeStyle: undefined,
  });
}

export function getFormattedDateTime(input?: string | number, defaultValue?: string): string {
  return getDefaultFormattedDateTime(input, defaultValue, { timeStyle: 'short' });
}

export function getFormattedTime(input?: string | number, defaultValue?: string): string {
  return getDefaultFormattedDateTime(input, defaultValue, {
    timeStyle: 'short',
    dateStyle: undefined,
  });
}

export function getFormattedShortDateTime(input?: string | number, defaultValue?: string): string {
  return getDefaultFormattedDateTime(input, defaultValue, {
    dateStyle: 'short',
    timeStyle: 'short',
  });
}

export function convertDateToUnixTimeSeconds(date: Date) {
  return Math.round(date.getTime() / 1000);
}

export function convertUnixTimeSecondsToISOFormat(seconds: number) {
  return new Date(seconds * 1000).toISOString();
}

export function convertISOFormatToUnixTimeSeconds(timestamp: string) {
  return convertDateToUnixTimeSeconds(parseISO(timestamp));
}

export function startOfDayInUtc(inputDate: Date): Date {
  const currentStartDate = startOfDay(inputDate).toDateString();
  const currentStartOfDayInUtc = new Date(`${currentStartDate} GMT`);
  return currentStartOfDayInUtc;
}

export function isValidDate(date?: Date): date is Date {
  return date instanceof Date && !isNaN(Number(date));
}

export const getFormattedDateTimeWithWeekday = (timestamp: string) =>
  capitalize(
    formatRelative(parseISO(timestamp), new Date(), {
      weekStartsOn: 1,
    }),
  );

export function getFormattedDistance(
  inputDate?: string | number | Date,
  defaultValue = '',
): string {
  if (!inputDate || !isValidDate(new Date(inputDate))) {
    return defaultValue;
  }

  return formatDistance(inputDate, new Date(), { includeSeconds: true, addSuffix: true });
}

export function getDateTimePickerValue(timeValue: string): DateValue | null {
  if (!timeValue) return null;
  const date = new Date(timeValue);
  if (!isValidDate(date)) {
    return null;
  }
  return toCalendarDateTime(parseAbsoluteToLocal(date.toISOString())) as unknown as DateValue;
}

import { useSearchParams } from 'react-router';
import { useSite } from 'src/context/SiteProvider';
import useOnMountEffect from './useOnMountEffect';

export function useAppInit() {
  const { setSelectedSiteId } = useSite();
  const [searchParams] = useSearchParams();

  useOnMountEffect(() => {
    const site = searchParams.get('site');
    if (site) {
      localStorage.setItem('siteId', site);
      setSelectedSiteId(site);
    }
  });
}

/* eslint-disable */
import type { TimeRange } from "../../../common/v1/common";

export const protobufPackage = "v1.site_manager";

/** Next ID: 7 */
export enum HydrationStatus {
  HYDRATION_JOB_STATUS_DEFAULT = "HYDRATION_JOB_STATUS_DEFAULT",
  HYDRATION_JOB_STATUS_DEPLOYING = "HYDRATION_JOB_STATUS_DEPLOYING",
  HYDRATION_JOB_STATUS_IN_PROGRESS = "HYDRATION_JOB_STATUS_IN_PROGRESS",
  HYDRATION_JOB_STATUS_COMPLETED = "HYDRATION_JOB_STATUS_COMPLETED",
  HYDRATION_JOB_STATUS_FAILED = "HYDRATION_JOB_STATUS_FAILED",
  HYDRATION_JOB_STATUS_DELETED = "HYDRATION_JOB_STATUS_DELETED",
  HYDRATION_JOB_STATUS_TERMINATED = "HYDRATION_JOB_STATUS_TERMINATED",
}

export interface HydrationJob {
  id: string;
  siteId: string;
  /** Source whose data is being hydrated */
  sourceId: string;
  /** Sink where data is being stored */
  sinkId: string;
  /** Time range of the data to be hydrated */
  timeRange: TimeRange | undefined;
  status: HydrationStatus;
  /** List of hosts */
  hosts: string[];
  created: string | undefined;
  updated: string | undefined;
  createdBy: string;
  updatedBy: string;
  /** Add addHydration tag on each of hydrated log lines */
  addHydrationTag: boolean;
  /** Current health of hydration job */
  health: HydrationJobHealth | undefined;
  reason: string;
  resource: HydrationJobResource | undefined;
}

export interface HydrationJobHealth {
  id: string;
  jobId: string;
  siteId: string;
  status: HydrationStatus;
  /** total data need to processed by hydration job */
  totalData: string;
  /** data processed so far by hydration job */
  dataProcessed: string;
  createdBy: string;
  updatedBy: string;
  totalFilesSkipped: string;
  latestSkippedFilesList: string[];
}

export interface HydrationJobResource {
  cpuRequest?: number | undefined;
  cpuLimit?: number | undefined;
  memoryRequest?: number | undefined;
  memoryLimit?: number | undefined;
}

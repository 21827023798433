import { Pipeline_PipelineStatus } from '@common-types/pipeline_manager/model/v1/pipeline';
import { PipelinesStatusFilterDisplayName } from 'src/types/PipelinesStatusFilters';

export const ALL_PIPELINE_STATUSES_FILTER: Pipeline_PipelineStatus[] = [];

export const DEFAULT_UI_PIPELINE_STATUS_FILTERS = [
  PipelinesStatusFilterDisplayName.Deployed,
  PipelinesStatusFilterDisplayName.Error,
  PipelinesStatusFilterDisplayName.NotDeployed,
  PipelinesStatusFilterDisplayName.Bypassed,
  PipelinesStatusFilterDisplayName.Paused,
];

export const ACTIVE_PIPELINE_STATUS_FILTERS = DEFAULT_UI_PIPELINE_STATUS_FILTERS;

export const UI_TO_API_PIPELINE_STATUS_MAP = {
  [PipelinesStatusFilterDisplayName.Deployed]: [
    Pipeline_PipelineStatus.ROLLED_BACK,
    Pipeline_PipelineStatus.DEPLOYED,
  ],
  [PipelinesStatusFilterDisplayName.Error]: [Pipeline_PipelineStatus.FAILED],
  [PipelinesStatusFilterDisplayName.NotDeployed]: [Pipeline_PipelineStatus.DEFAULT],
  [PipelinesStatusFilterDisplayName.Deleted]: [Pipeline_PipelineStatus.DELETED],
  [PipelinesStatusFilterDisplayName.Bypassed]: [Pipeline_PipelineStatus.BYPASSED],
  [PipelinesStatusFilterDisplayName.Paused]: [Pipeline_PipelineStatus.PAUSED],
};

export const DEFAULT_NODE_PIPELINES_STATUS_FILTERS = [
  Pipeline_PipelineStatus.ROLLED_BACK,
  Pipeline_PipelineStatus.DEPLOYED,
  Pipeline_PipelineStatus.DEFAULT,
];

export const DEFAULT_PIPELINES_NODE_STATUS_FILTERS = [
  Pipeline_PipelineStatus.ROLLED_BACK,
  Pipeline_PipelineStatus.DEPLOYED,
  Pipeline_PipelineStatus.DEFAULT,
  Pipeline_PipelineStatus.BYPASSED,
  Pipeline_PipelineStatus.PAUSED,
  Pipeline_PipelineStatus.FAILED,
  Pipeline_PipelineStatus.DELETED,
];

export const ROUTE_PATHS = {
  all: '*',
  root: '/',
  login: '/login',
  dashboard: '/dashboard',
  pipelines: {
    index: '/pipelines',
    create: '/pipelines/create',
    pipelineDetails: '/pipelines/:pipelineId',
  },
  sources: {
    index: '/sources',
    sourceDetails: '/sources/:sourceId',
  },
  destinations: '/destinations',
  hydration: '/hydration',
  logSearch: '/log-search',
  monitoring: {
    index: '/monitoring',
    analytics: '/monitoring/analytics',
    pipelineErrors: '/monitoring/pipelineErrors',
  },
  notifications: '/notifications',
  events: {
    index: '/events',
    notifications: '/events/notifications',
    pullRequests: '/events/pullRequests',
  },
  alerts: '/alerts',
  reports: '/reports',
  settings: {
    index: '/settings',
    apiKeys: '/settings/apiKeys',
    sites: '/settings/sites',
    siteDetails: '/settings/sites/:siteId',
    files: '/settings/files',
    sso: '/settings/sso',
    roles: '/settings/roles',
    roleDetails: '/settings/roles/:roleId',
    users: '/settings/users',
    alertManagement: '/settings/alertManagement',
    channelManagement: '/settings/channelManagement',
    fleets: '/settings/fleets',
    fleetDetails: '/settings/fleets/:fleetId',
    agents: '/settings/agents',
    configurations: {
      index: '/settings/configurations',
      create: '/settings/configurations/create',
      configurationDetails: '/settings/configurations/:configId',
    },
  },
};

export enum RouteTitle {
  Dashboard = 'Dashboard',
  Pipelines = 'Pipelines',
  Destinations = 'Destinations',
  Sources = 'Sources',
  Monitoring = 'Monitoring',
  Hydration = 'Hydration',
  LogSearch = 'Log Search',
  Notifications = 'Notifications',
  Alerts = 'Alerts',
  Settings = 'Settings',
  Reports = 'Reports',
  Events = 'Events',
}

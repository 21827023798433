import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  header: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  body: {
    padding: '24px',
    fontSize: '1rem',
  },
  dialog: {
    _dark: {
      transition: 'max-width 200ms linear',
      bg: 'dialogBackground',
    },
  },
});

export const Drawer = defineMultiStyleConfig({
  baseStyle,
});

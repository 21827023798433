export const INVALID_EMAIL_ERROR_DESCRIPTIONS = ['Email not verified.'];

export const LOGIN_ERROR_MODAL_TEXTS = {
  NOT_AUTHORIZED: {
    header: 'Not Authorized',
    body: `Hi there, you don't seem to have access to Observo AI.
    If you haven't set up your account yet, please reach out to us at info@observo.ai. Otherwise, please get in touch with your organization's administrator.`,
  },
  INVALID_EMAIL: {
    header: 'Validate Email',
    body: `Check your email inbox for a message from Observo AI and
    click on the provided link to verify your email address.
    Once the verification process is complete, please log in again to continue.`,
  },
  FORBIDDEN: {
    header: 'Forbidden',
    body: `You currently do not have access to the application resources. Please reach out to your organization's administrator for assistance.`,
  },
  ERROR: {
    header: 'Something went wrong!',
    body: `We have encountered an unexpected issue. Please try again later, and if the problem persists, feel free to contact us for assistance.`,
  },
};

export const ERROR_COUNT_POLL_INTERVAL_MS = 2 * 60 * 1000;
export const RESPONSE_ERROR_REASON_PATH = 'response.data.reason';
export const RESPONSE_ERROR_CODE_PATH = 'response.data.code';
export const RESPONSE_ERROR_MESSAGE_PATH = 'response.data.message';
export const API_ERROR_MESSAGE_CHAR_LIMIT = 400;
export const RTK_ERROR_EVENT_NAME = 'rtkApiError';

import React, { ReactNode } from 'react';
import {
  Button,
  Divider,
  Heading,
  HStack,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ThemingProps,
} from '@chakra-ui/react';
import { COLORS } from 'src/constants/colors';
import { Z_INDEX_OVER_TOAST } from 'src/constants/modal';
import { dividerBgColor, headerBgColor } from 'src/utils/dialog';
import { TrashRedIcon } from '../Icons/TrashRedIcon';
import { InfoBlueIcon } from '../Icons/InfoBlueIcon';
import { AlertTriangleIcon } from '../Icons/AlertTriangleIcon';
import { InfoIcon } from '../Icons/InfoIcon';
import { DeleteIcon } from '../Icons/DeleteIcon';

export type DialogType = 'info' | 'alert' | 'delete' | 'error';

const DIALOG_ICON_MAP: Record<DialogType, ReactNode> = {
  info: <InfoBlueIcon fill="none" />,
  alert: <AlertTriangleIcon fill="none" />,
  delete: <TrashRedIcon fill="none" />,
  error: <InfoIcon color={COLORS.status.error} fill="none" />,
};

type DialogProps = {
  size?: ThemingProps['size'];
  type: DialogType;
  actionLabel: string;
  cancelLabel?: string;
  title: string;
  bodyContent: React.ReactNode;
  footerContent?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onActionClick: () => void;
  isLoading?: boolean;
  cancelOnly?: boolean;
  shouldUseCustomZIndex?: boolean;
};

const Dialog = ({
  size = 'lg',
  type,
  isOpen,
  onClose,
  actionLabel,
  cancelLabel = 'Cancel',
  title,
  bodyContent,
  footerContent,
  onActionClick,
  isLoading = false,
  cancelOnly = false,
  shouldUseCustomZIndex = false,
}: DialogProps) => {
  const isDelete = type === 'delete';

  return (
    <ChakraModal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay
        backdropFilter="blur(5px)"
        zIndex={shouldUseCustomZIndex ? Z_INDEX_OVER_TOAST : 'var(--chakra-zIndices-modal)'}
      />
      <ModalContent
        containerProps={{
          zIndex: shouldUseCustomZIndex ? Z_INDEX_OVER_TOAST : 'var(--chakra-zIndices-modal)',
        }}
      >
        <ModalHeader
          bgColor={headerBgColor(type)}
          borderTopRadius="8px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack>
            {DIALOG_ICON_MAP[type]}
            <Heading variant="title-4">{title}</Heading>
          </HStack>
          <DeleteIcon onClick={onClose} cursor="pointer" />
        </ModalHeader>
        <Divider bgColor={dividerBgColor(type)} />
        <ModalBody>{bodyContent}</ModalBody>
        <Divider />
        <ModalFooter>
          {footerContent ? (
            footerContent
          ) : (
            <>
              <Button isLoading={isLoading} mr={4} onClick={onClose} variant="outline">
                {cancelLabel}
              </Button>
              {!cancelOnly && (
                <Button
                  variant={isDelete ? 'delete' : 'solid'}
                  isLoading={isLoading}
                  onClick={onActionClick}
                >
                  {actionLabel}
                </Button>
              )}
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default Dialog;

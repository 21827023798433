import { HydrationStatus } from '@common-types/site_manager/model/v1/hydration';

export const DEFAULT_HYDRATION_STATUS_FILTER_VALUES = [
  `${HydrationStatus.HYDRATION_JOB_STATUS_DEFAULT},${HydrationStatus.HYDRATION_JOB_STATUS_IN_PROGRESS}`,
  HydrationStatus.HYDRATION_JOB_STATUS_DEPLOYING,
  HydrationStatus.HYDRATION_JOB_STATUS_COMPLETED,
  HydrationStatus.HYDRATION_JOB_STATUS_FAILED,
];

export enum HydrationTableHeaderValue {
  Source = 'source',
  Destination = 'destination',
  TimeRange = 'time_range',
  DataVolumeHydrated = 'data_volume_hydrated',
  Status = 'status',
  Divider = 'divider',
  Progress = 'progress',
  Age = 'age',
}

export const HYDRATION_TABLE_HEADERS = [
  { value: HydrationTableHeaderValue.Source, label: 'Source' },
  { value: HydrationTableHeaderValue.Destination, label: 'Destination' },
  { value: HydrationTableHeaderValue.TimeRange, label: 'Time Range' },
  { value: HydrationTableHeaderValue.DataVolumeHydrated, label: 'Data Volume', width: '120px' },
  { value: HydrationTableHeaderValue.Progress, label: 'Progress', width: '160px' },
  { value: HydrationTableHeaderValue.Status, label: 'Status', width: '120px' },
  { value: HydrationTableHeaderValue.Age, label: 'Age', width: '120px' },
];

export const HYDRATION_ITEM_CELL_TEXT_STYLE = {
  color: 'label',
  fontSize: '14px',
};

export enum HydrationStatusFilterDisplayName {
  InProgress = 'In Progress',
  Failed = 'Failed',
  Completed = 'Completed',
  Deploying = 'Deploying',
  Deleted = 'Deleted',
  Terminated = 'Terminated',
}

export const HYDRATION_STATUS_FILTER_MAP: { [key: string]: HydrationStatusFilterDisplayName } = {
  [HydrationStatus.HYDRATION_JOB_STATUS_DEFAULT]: HydrationStatusFilterDisplayName.InProgress,
  [HydrationStatus.HYDRATION_JOB_STATUS_DEPLOYING]: HydrationStatusFilterDisplayName.Deploying,
  [HydrationStatus.HYDRATION_JOB_STATUS_IN_PROGRESS]: HydrationStatusFilterDisplayName.InProgress,
  [HydrationStatus.HYDRATION_JOB_STATUS_COMPLETED]: HydrationStatusFilterDisplayName.Completed,
  [HydrationStatus.HYDRATION_JOB_STATUS_FAILED]: HydrationStatusFilterDisplayName.Failed,
  [HydrationStatus.HYDRATION_JOB_STATUS_DELETED]: HydrationStatusFilterDisplayName.Deleted,
  [HydrationStatus.HYDRATION_JOB_STATUS_TERMINATED]: HydrationStatusFilterDisplayName.Terminated,
};

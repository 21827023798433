import {
  PipelineErrorDetail,
  GetPipelinesErrorCountResponse,
  AcknowledgeErrorsRequest,
} from '@common-types/analytics_service/api/v1/analytics_service';
import { AcknowledgementState } from '@common-types/analytics_service/conf/v1/analytics-config';
import apiSlice from 'src/store/api';
import { RTK_QUERY_DEFAULT_STALE_TIME } from 'src/constants/queries';
import { getApiTimeRangeSeconds } from 'src/utils/apiTimeRangeParams';
import { MAX_DATE_FILTER_VALUE } from 'src/constants/dateFilter';
import { isIgnorePipelineErrors } from 'src/utils/pipelineErrors';

interface PipelineErrorCountParams {
  siteId: string;
  acknowledgeState: AcknowledgementState;
}

interface PipelineErrorListParams {
  siteId: string;
  timeValue: string;
  pipelineIds?: string[];
}

const API_TAG_PIPELINE_ERROR_COUNT = 'monitoringPipelineErrorCount';
const API_TAG_PIPELINE_ERROR_LIST = 'monitoringPipelineErrorList';

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [API_TAG_PIPELINE_ERROR_LIST, API_TAG_PIPELINE_ERROR_COUNT],
});

const injectedEndpoints = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    pipelineErrorList: build.query<PipelineErrorDetail[], PipelineErrorListParams>({
      query: ({ siteId, pipelineIds, timeValue }) => ({
        url: '/v1/analytics/pipelines/errors/list',
        params: {
          siteId,
          pipelineIds,
          ...getApiTimeRangeSeconds(timeValue, true),
        },
      }),
      transformResponse: (response) => {
        if (isIgnorePipelineErrors()) {
          return [];
        }

        return response.pipelinesErrorDetails;
      },
      keepUnusedDataFor: RTK_QUERY_DEFAULT_STALE_TIME,
      providesTags: [API_TAG_PIPELINE_ERROR_LIST],
    }),
    pipelineErrorCount: build.query<GetPipelinesErrorCountResponse, PipelineErrorCountParams>({
      query: ({ siteId, acknowledgeState }) => ({
        url: '/v1/analytics-service/pipelines/errors/count',
        params: {
          siteId,
          acknowledgeState,
          ...getApiTimeRangeSeconds(MAX_DATE_FILTER_VALUE),
        },
      }),
      keepUnusedDataFor: RTK_QUERY_DEFAULT_STALE_TIME,
      providesTags: [API_TAG_PIPELINE_ERROR_COUNT],
    }),
    ackUnreadErrors: build.mutation<unknown, AcknowledgeErrorsRequest>({
      query: (data) => ({
        url: '/v1/analytics-service/components/errors/acknowledge',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [API_TAG_PIPELINE_ERROR_COUNT, API_TAG_PIPELINE_ERROR_LIST],
    }),
  }),
  overrideExisting: false,
});

export const { usePipelineErrorListQuery, usePipelineErrorCountQuery, useAckUnreadErrorsMutation } =
  injectedEndpoints;

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NodeStatus } from '@common-types/site_manager/model/v1/node';
import {
  AddSourceResponse,
  DeleteSourceResponse,
  DisableArchivalForSourceRequest,
  DisableArchivalForSourceResponse,
  EnableArchivalForSourceRequest,
  EnableArchivalForSourceResponse,
  ListSourcesResponse,
  UpdateSourceResponse,
} from '@common-types/site_manager/api/v1/site_api';
import { NotificationStatus } from 'src/components/Notification/types';
import { DEFAULT_PAGINATION_ARGS } from 'src/constants/queries';
import notificationsSlice from 'src/store/notifications';
import { PaginationRequestParams, PaginationSortParams } from 'src/types/PaginationRequest';
import { Source } from 'src/types/Source';
import { API_TAG_NODE_NAMES_LIST, API_TAG_SOURCES_LIST, sourcesApiWithTag } from '.';

const { showNotification } = notificationsSlice.actions;

interface ListSourcesRequest {
  siteIds: string[];
  pipelineIds?: string[];
  templateIds?: string[];
  statuses?: NodeStatus[];
  sourceIds?: string[];
  pagination?: PaginationRequestParams;
  paginationSort?: PaginationSortParams;
  includeConfig?: boolean;
}

export interface AddSourceMutationRequest {
  source: Source;
}

export interface UpdateSourceMutationRequest extends AddSourceMutationRequest {
  lastUpdated?: string;
}

const sourcesInjectedEndpoints = sourcesApiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getSources: build.query<ListSourcesResponse, ListSourcesRequest>({
      query: ({
        siteIds = [],
        templateIds = [],
        statuses = [],
        sourceIds = [],
        pagination = DEFAULT_PAGINATION_ARGS,
        paginationSort,
        includeConfig = true,
      }) => ({
        url: '/v1/sources',
        params: {
          siteIds,
          templateIds,
          statuses,
          sourceIds,
          includeConfig,
          ...pagination,
          'pagination.ordering.fieldName': 'updated_at',
          'pagination.ordering.descending': true,
          ...paginationSort,
        },
      }),
      providesTags: [API_TAG_SOURCES_LIST],
    }),

    addSource: build.mutation<AddSourceResponse, AddSourceMutationRequest>({
      query: (data) => ({
        url: '/v1/source',
        method: 'POST',
        body: data,
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            showNotification({
              status: NotificationStatus.Success,
              message: 'Source added successfully.',
              shouldTimeout: true,
            }),
          );
        });
      },
      invalidatesTags: (response) =>
        response ? [API_TAG_SOURCES_LIST, API_TAG_NODE_NAMES_LIST] : [],
    }),

    updateSource: build.mutation<UpdateSourceResponse, UpdateSourceMutationRequest>({
      query: (data) => ({
        url: '/v1/source',
        method: 'PATCH',
        body: data,
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            showNotification({
              status: NotificationStatus.Success,
              message: 'Source updated successfully.',
              shouldTimeout: true,
            }),
          );
        });
      },
      invalidatesTags: (response) =>
        response ? [API_TAG_SOURCES_LIST, API_TAG_NODE_NAMES_LIST] : [],
    }),

    deleteSource: build.mutation<DeleteSourceResponse, string>({
      query: (sourceId) => ({
        url: `/v1/source/${sourceId}`,
        method: 'DELETE',
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            showNotification({
              status: NotificationStatus.Success,
              message: 'Source successfully removed.',
              shouldTimeout: true,
            }),
          );
        });
      },
      invalidatesTags: (response) =>
        response ? [API_TAG_SOURCES_LIST, API_TAG_NODE_NAMES_LIST] : [],
    }),

    enableArchivalSource: build.mutation<
      EnableArchivalForSourceResponse,
      EnableArchivalForSourceRequest
    >({
      query: (data) => ({
        url: '/v1/source/enable-archival',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (response) => (response ? [API_TAG_SOURCES_LIST] : []),
    }),

    disableArchivalSource: build.mutation<
      DisableArchivalForSourceResponse,
      DisableArchivalForSourceRequest
    >({
      query: (data) => ({
        url: '/v1/source/disable-archival',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (response) => (response ? [API_TAG_SOURCES_LIST] : []),
    }),
  }),

  overrideExisting: false,
});

export function useInvalidateSourcesCache() {
  const dispatch = useDispatch();

  const invalidateSourcesCache = useCallback(() => {
    dispatch(sourcesApiWithTag.util.invalidateTags([API_TAG_SOURCES_LIST]));
  }, [dispatch]);

  return invalidateSourcesCache;
}

export const {
  useGetSourcesQuery,
  useAddSourceMutation,
  useUpdateSourceMutation,
  useDeleteSourceMutation,
  useEnableArchivalSourceMutation,
  useDisableArchivalSourceMutation,
} = sourcesInjectedEndpoints;

/* eslint-disable */
import type { Status } from "../../../common/v1/common";

export const protobufPackage = "v1.analytics_service";

/** Enum for different channel types */
export enum ChannelType {
  CHANNEL_DEFAULT = "CHANNEL_DEFAULT",
  SLACK = "SLACK",
  EMAIL = "EMAIL",
  WEBHOOK = "WEBHOOK",
  MSTEAMS = "MSTEAMS",
  OPSGENIE = "OPSGENIE",
}

/** Enum for alert categories */
export enum AlertCategory {
  ALERT_CATEGORY_DEFAULT = "ALERT_CATEGORY_DEFAULT",
}

/** Enum for alert severity levels */
export enum AlertSeverity {
  SEVERITY_UNSPECIFIED = "SEVERITY_UNSPECIFIED",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL",
}

export enum FiringState {
  FIRING_STATE_INACTIVE = "FIRING_STATE_INACTIVE",
  RESOLVED = "RESOLVED",
  PENDING = "PENDING",
  FIRING = "FIRING",
}

export enum AlertEventState {
  ALERT_EVENT_STATE_INACTIVE = "ALERT_EVENT_STATE_INACTIVE",
  ALERT_EVENT_STATE_RESOLVED = "ALERT_EVENT_STATE_RESOLVED",
  ALERT_EVENT_STATE_FIRING = "ALERT_EVENT_STATE_FIRING",
  ALERT_EVENT_STATE_PENDING = "ALERT_EVENT_STATE_PENDING",
  ALERT_EVENT_STATE_DISCARDED = "ALERT_EVENT_STATE_DISCARDED",
}

export enum AcknowledgementState {
  ACKNOWLEDGEMENT_ALL = "ACKNOWLEDGEMENT_ALL",
  ACKNOWLEDGED = "ACKNOWLEDGED",
  UNACKNOWLEDGED = "UNACKNOWLEDGED",
}

export enum ErrorLevel {
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}

export enum UnitCostType {
  UNIT_COST_TYPE_UNSPECIFIED = "UNIT_COST_TYPE_UNSPECIFIED",
  /** GB_PER_MONTH - GB_PER_DAY, GB_PER_HOUR, TB_PER_DAY, TB_PER_MONTH, TB_PER_HOUR */
  GB_PER_MONTH = "GB_PER_MONTH",
}

export interface AnalyticsServerConfig {
  prometheusconfig: PrometheusConfig | undefined;
  useDummy: boolean;
  ssaConfig: SSAConfig | undefined;
  clickhouseConfig: ClickHouseConfig | undefined;
  patternStoreConfig: PatternStoreConfig | undefined;
  pdfGenConfig: PDFGeneratorConfig | undefined;
}

export interface PatternStoreConfig {
  storeName: string;
  enabled: string;
  keepTopKPatternsForIngestion: boolean;
  topKPatternsForIngestion: number;
}

export interface SSAConfig {
  streamAnalyticsMergerIntervalHour: number;
  trafficProtocolMapper: { [key: string]: string };
}

export interface SSAConfig_TrafficProtocolMapperEntry {
  key: string;
  value: string;
}

export interface PrometheusConfig {
  url: string;
  timeout: number;
  helmReleaseName: string;
  alertRulesNamespace: string;
}

/** Comma separated addresses */
export interface ClickHouseConfig {
  addresses: string;
  database: string;
  username: string;
  password: string;
  maxOpenConnections: number;
  maxIdleConnections: number;
  connMaxLifetime: string;
  dialTimeout: string;
  freeBufOnConnRelease: boolean;
  maxCompressionBuffer: number;
  blockBufferSize: number;
}

export interface PDFGeneratorConfig {
  url: string;
}

/** Message for Slack channel configuration */
export interface SlackChannel {
  /** URL for Slack webhook */
  webhookUrl: string;
}

/** Message for Email channel configuration */
export interface EmailChannel {
  /** Email address for notifications */
  emailAddress: string;
}

/** Message for Webhook channel configuration */
export interface WebhookChannel {
  /** URL for webhook */
  webhookUrl: string;
  /** Headers for webhook http call */
  headers: { [key: string]: string };
}

export interface WebhookChannel_HeadersEntry {
  key: string;
  value: string;
}

/** Message for MS Teams channel configuration */
export interface MSTeamsChannel {
  /** URL for webhook */
  webhookUrl: string;
}

/** Message for OpsGenie channel configuration */
export interface OpsGenieChannel {
  /** URL for api */
  apiUrl: string;
  /** key for api */
  apiKey: string;
}

/** Message for Channel */
export interface Channel {
  /** Unique identifier for the channel */
  id: string;
  /** Organization ID */
  orgId: string;
  /** Name of the channel */
  name: string;
  /** Description of the channel */
  description: string;
  /** Type of the channel (e.g., Slack, Email, Webhook) */
  channelType: ChannelType;
  /** User who created the channel */
  createdBy: string;
  /** User who last updated the channel */
  updatedBy: string;
  /** Timestamp when the channel was created */
  created:
    | string
    | undefined;
  /** Timestamp when the channel was last updated */
  updated: string | undefined;
  slackConfig?: SlackChannel | undefined;
  emailConfig?: EmailChannel | undefined;
  webhookConfig?: WebhookChannel | undefined;
  msTeams?: MSTeamsChannel | undefined;
  opsGenie?: OpsGenieChannel | undefined;
  status: Status;
  alerts: Alert[];
}

/** Message for Alert Template */
export interface AlertTemplate {
  /** Unique identifier for the alert template */
  id: string;
  /** Name of the alert template */
  name: string;
  /** Name of the alert template */
  version: string;
  displayName: string;
  /** Description of the alert template */
  description: string;
  /** Category of the alert template */
  category: AlertCategory;
  /** Configuration format for the alert template */
  configFormat:
    | { [key: string]: any }
    | undefined;
  /** Timestamp when the alert template was last updated */
  updated: string | undefined;
}

/** Message for Alert */
export interface Alert {
  /** Unique identifier for the alert */
  id: string;
  /** Name of the alert */
  name: string;
  /** Site IDs associated with the alert */
  siteId: string;
  /** ID of the alert template */
  templateId: string;
  /** Description of the alert */
  description: string;
  /** Channel IDs for notification */
  channels: Channel[];
  /** active, inactive, deleted */
  status: Status;
  /** Timestamp when the alert was created */
  created:
    | string
    | undefined;
  /** Timestamp when the alert was last updated */
  updated:
    | string
    | undefined;
  /** User who created the alert */
  createdBy: string;
  /** User who last updated the alert */
  updatedBy: string;
  /** Configuration for the alert template */
  config:
    | { [key: string]: any }
    | undefined;
  /** Timestamp when the alert was created */
  lastTrigger:
    | string
    | undefined;
  /** Firing, resolved, pending */
  firingState: FiringState;
  /** Severity level of the alert template */
  severity: AlertSeverity;
  /** User defined tags */
  userTags: { [key: string]: string };
}

export interface Alert_UserTagsEntry {
  key: string;
  value: string;
}

export interface PrometheusAlertEvent {
  status: string;
  labels: { [key: string]: any } | undefined;
  annotations: { [key: string]: any } | undefined;
  generatorURL: string;
  fingerprint: string;
  startsAt: string | undefined;
  endsAt: string | undefined;
}

export interface PrometheusAlertManagerEvent {
  version: string;
  /** key identifying the group of alerts (e.g. to deduplicate) */
  groupKey: string;
  /** how many alerts have been truncated due to "max_alerts" */
  truncatedAlerts: number;
  status: string;
  receiver: string;
  groupLabels: { [key: string]: any } | undefined;
  commonLabels: { [key: string]: any } | undefined;
  commonAnnotations:
    | { [key: string]: any }
    | undefined;
  /** backlink to the Alertmanager */
  externalURL: string;
  alerts: PrometheusAlertEvent[];
}

export interface ObservoAlertEvent {
  alertEventId: string;
  siteId: string;
  /** id of the corresponding alert object */
  alertId: string;
  /** name of the corresponding alert object */
  alertName: string;
  severity: AlertSeverity;
  /** fingerprint of prometheus alertmanager event */
  fingerprint: string;
  /** Current state of the active alert */
  state: AlertEventState;
  /** Event message sent by prometheus alertmanager */
  eventMessage:
    | { [key: string]: any }
    | undefined;
  /** id of observo component associated with the alert */
  componentId: string;
  /** Timestamp when the event was produced */
  firstEventAt:
    | string
    | undefined;
  /** Timestamp when the event was last updated */
  lastEventAt: string | undefined;
  isAcknowledged: boolean;
}

export interface UnitCost {
  unitType: UnitCostType;
  value: number;
}

export interface ReportConfig {
  siteId: string;
  pipelineIds: string[];
  componentIds: string[];
  unitCost: UnitCost | undefined;
}

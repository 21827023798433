import { getEnvVar } from 'src/utils/getEnvVar';

/**
 * Need to define these env vars in index.html as well.
 */
enum FeatureFlag {
  LogSearch = 'REACT_APP_LOG_SEARCH_FEATURE_FLAG',
  LogSearchV2 = 'REACT_APP_LOG_SEARCH_V2_FEATURE_FLAG',
  Sso = 'REACT_APP_SSO_FEATURE_FLAG',
  IgnoreErrors = 'REACT_APP_IGNORE_ERRORS_FEATURE_FLAG',
  RiggedValues = 'REACT_APP_RIGGED_VALUES_FEATURE_FLAG',
  ApiKeys = 'REACT_APP_API_KEYS_FEATURE_FLAG',
  GuidedWalkthroughEnable = 'REACT_APP_GUIDED_WALKTHROUGH_ENABLE',
  Rbac = 'REACT_APP_RBAC_FLAG',
  UserReports = 'REACT_APP_USER_REPORTS',
  ShowAlertEventsPage = 'REACT_APP_SHOW_ALERT_EVENTS_PAGE',
  ShowChatbot = 'REACT_APP_SHOW_CHATBOT',
  GitOps = 'REACT_APP_GIT_OPS',
  FleetManagement = 'REACT_APP_FLEET_MANAGEMENT',
  AIRecommendations = 'REACT_APP_AI_RECOMMENDATIONS',
}

function isDevEnv(): boolean {
  return getEnvVar('REACT_APP_ENV', false) === 'DEV';
}

export function isE2eEnv(): boolean {
  return (
    getEnvVar('REACT_APP_ENV', false) === 'E2E' ||
    ('Cypress' in window && Cypress.env('REACT_APP_ENV') === 'E2E')
  );
}

function isFeatureFlagOn(featureFlag: FeatureFlag): boolean {
  return getEnvVar(featureFlag, false) === 'true';
}

export function isLogSearchFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.LogSearch) || isDevEnv();
}

export function isLogSearchV2FeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.LogSearchV2) || isDevEnv();
}

export function isSsoFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.Sso) || isDevEnv() || isE2eEnv();
}

export function isIgnoreErrorsFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.IgnoreErrors);
}

export function isRiggedValuesFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.RiggedValues);
}

export function isGuidedWalkthroughFeatureEnable(): boolean {
  return isFeatureFlagOn(FeatureFlag.GuidedWalkthroughEnable);
}

export function isApiKeysFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.ApiKeys) || isDevEnv();
}

export function isRbacFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.Rbac) || isDevEnv() || isE2eEnv();
}

export function isShowAlertEventsPageEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.ShowAlertEventsPage) || isDevEnv() || isE2eEnv();
}

export function isUserReportsFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.UserReports) || isDevEnv() || isE2eEnv();
}

export function isChatbotFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.ShowChatbot) || isDevEnv();
}

export function isGitOpsFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.GitOps) || isDevEnv() || isE2eEnv();
}

export function isFleetManagementFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.FleetManagement) || isDevEnv();
}

export function isAIRecommendationsFeatureEnabled(): boolean {
  return isFeatureFlagOn(FeatureFlag.AIRecommendations) || isDevEnv();
}

export enum PipelinesStatusFilterDisplayName {
  Deployed = 'Deployed',
  NotDeployed = 'Not Deployed',
  Error = 'Error',
  Paused = 'Paused',
  Bypassed = 'Bypassed',
  Deleted = 'Deleted',
}

export enum PipelinesPendingStatusDisplayName {
  Deploying = 'Deploying',
  Deleting = 'Deleting',
  Pausing = 'Pausing',
  Bypassing = 'Bypassing',
}

export enum StatusFilterMapValue {
  RolledBack = 'ROLLED_BACK',
  Deployed = 'DEPLOYED',
  Failed = 'FAILED',
  Default = 'DEFAULT',
  Deleted = 'DELETED',
  Bypassed = 'BYPASSED',
  Paused = 'PAUSED',
  Deploying = 'DEPLOY',
  Deleting = 'Delete',
  Bypassing = 'BYPASS',
  Pausing = 'PAUSE',
}

import { Flex, Spinner } from '@chakra-ui/react';
import { Badge } from 'src/components/Badge/Badge';
import { NOTIFICATIONS_POLLING_INTERVAL } from 'src/constants/notifications';
import { useSite } from 'src/context/SiteProvider';
import { useNotificationsSummaryQuery } from 'src/store/api/notificationList';

export function NotificationCount() {
  const { siteIds } = useSite();

  const { currentData: notificationsSummary, isFetching } = useNotificationsSummaryQuery(
    { siteIds },
    { pollingInterval: NOTIFICATIONS_POLLING_INTERVAL, skipPollingIfUnfocused: true },
  );

  const numOfUnreadNotifications = notificationsSummary?.errorSummary?.unreadCount;

  const shouldShowNotificationBadge =
    numOfUnreadNotifications !== undefined && numOfUnreadNotifications > 0;

  return (
    <Flex justifyContent="end" w="full">
      {isFetching ? (
        <Spinner width={6} height={6} />
      ) : (
        shouldShowNotificationBadge && <Badge count={numOfUnreadNotifications} />
      )}
    </Flex>
  );
}

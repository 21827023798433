import { useCallback } from 'react';
import { useSite } from 'src/context/SiteProvider';
import { Source } from 'src/types/Source';
import { Source as SourceType } from '@common-types/site_manager/model/v1/node';
import {
  AddSourceMutationRequest,
  UpdateSourceMutationRequest,
  useAddSourceMutation,
  useDeleteSourceMutation,
  useDisableArchivalSourceMutation,
  useEnableArchivalSourceMutation,
  useUpdateSourceMutation,
} from 'src/store/api/components/sources';
import { useRaisePr } from './useRaisePr';

export function useSourceMutations() {
  const { siteId } = useSite();
  const [mutateDeleteSource, { isLoading: isDeleteSourceMutating }] = useDeleteSourceMutation();
  const [mutateAddSource, { isLoading: isAddSourceMutating }] = useAddSourceMutation();
  const [mutateEnableArchivalForSource] = useEnableArchivalSourceMutation();
  const [mutateDisableArchivalForSource] = useDisableArchivalSourceMutation();
  const [mutateUpdateSource, { isLoading: isUpdateSourceMutating }] = useUpdateSourceMutation();
  const { handleComponentMutations } = useRaisePr();

  const deleteSourceMutation = useCallback(
    async (source: SourceType) =>
      await handleComponentMutations(async () => await mutateDeleteSource(source.id).unwrap(), {
        nodes: { [source.name]: { source: source as Source } },
      }),
    [mutateDeleteSource, handleComponentMutations],
  );

  const updateSourceMutation = useCallback(
    async (request: UpdateSourceMutationRequest, oldName: string) =>
      await handleComponentMutations(async () => await mutateUpdateSource(request).unwrap(), {
        nodes: { [oldName]: { source: request.source } },
      }),
    [mutateUpdateSource, handleComponentMutations],
  );

  const addSourceMutation = useCallback(
    async (request: AddSourceMutationRequest) =>
      await handleComponentMutations(async () => await mutateAddSource(request).unwrap(), {
        nodes: { [request.source.name]: { source: request.source } },
      }),
    [mutateAddSource, handleComponentMutations],
  );

  const mutateArchivalForSource = useCallback(
    async (sourceId: string, sinkId?: string) => {
      if (sinkId) {
        return await mutateEnableArchivalForSource({ siteId, sourceId, sinkId }).unwrap();
      } else {
        return await mutateDisableArchivalForSource({ siteId, sourceId }).unwrap();
      }
    },
    [mutateDisableArchivalForSource, mutateEnableArchivalForSource, siteId],
  );

  return {
    isAddSourceMutating,
    isUpdateSourceMutating,
    isDeleteSourceMutating,
    updateSourceMutation,
    addSourceMutation,
    deleteSourceMutation,
    mutateArchivalForSource,
  };
}

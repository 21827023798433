import { PipelineType } from '@common-types/pipeline_manager/model/v1/pipeline';
import { SOURCE_LOG_PARSER_FORM_PARENT_KEY, SOURCE_ARCHIVAL_FORM_KEY } from './sourceFormMiscKeys';

export const PIPELINES_CREATION_FORM_WIDTH = '80%';
export const PIPELINE_DEPLOYMENT_POLL_INTERVAL_MS = 3000;
export const PIPELINES_CREATION_SCROLLABLE_STEP_HEIGHT = 'calc(100vh - 330px)';

export const SOURCE_SINK_ADDITIONAL_REQUIRED_FIELDS = [
  {
    label: 'Name',
    name: 'name',
    placeholder: 'Name',
    type: 'string',
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Description',
    type: 'string',
  },
];

export const TEMP_FORM_FIELD_NAMES = ['_temp'];

export const SOURCE_SINK_ADDITIONAL_REQUIRED_FIELDS_NAMES = ['name'];
export const SOURCE_SINK_ADDITIONAL_SKIP_FIELDS_NAMES = [
  'description',
  ...TEMP_FORM_FIELD_NAMES,
  SOURCE_LOG_PARSER_FORM_PARENT_KEY,
  SOURCE_ARCHIVAL_FORM_KEY,
];

export const DEFAULT_PIPELINE_NAME = 'My Pipeline';

export const NON_DEPLOYED_GRAPH_VERSION = 0;

export const DESTINATION_TABLE_HEADERS = [
  { value: 'name', label: 'Name' },
  { value: 'type', label: 'Type' },
  { value: 'status', label: 'Status', width: '120px' },
  { value: 'dataOutput', label: 'Data Output', width: '120px' },
  { value: 'archival', label: 'Archival Destination', width: '120px' },
  { value: 'pipelines', label: 'Pipeline(s)' },
];

export const SOURCE_TABLE_HEADERS = [
  { value: 'name', label: 'Name' },
  { value: 'type', label: 'Type' },
  { value: 'status', label: 'Status', width: '120px' },
  { value: 'dataInput', label: 'Data Input', width: '120px' },
  { value: 'archival', label: 'Archival Destination' },
  { value: 'pipelines', label: 'Pipeline(s)' },
];

export const SINK_ADDITIONAL_SKIP_FIELDS_NAMES = ['isArchival', 'sourceId'];

export const PIPELINE_TABLE_HEADERS = [
  { value: 'name', label: 'Name' },
  { value: 'source', label: 'Source' },
  { value: 'destination', label: 'Destination' },
  { value: 'dataOptimized', label: 'Data Optimized', width: '200px' },
  { value: 'errors', label: 'Pipeline Errors', width: '120px' },
  { value: 'status', label: 'Status', width: '180px' },
  { value: 'actionMenu', label: '', width: '100px' },
];

export const DUPLICATE_PIPELINE_NAME_MESSAGE = 'Pipeline name already exists';

export const DEFAULT_LIST_PIPELINES_TYPE_FILTER = [PipelineType.PIPELINE_TYPE_USER];

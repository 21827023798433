import {
  GetPipelineNodeIdsResponse,
  GetNodePipelineIdsResponse,
} from '@common-types/pipeline_manager/api/v1/pipeline_api';
import { ListNodeNamesResponse } from '@common-types/site_manager/api/v1/site_api';
import { NodeStatus, NodeTypes } from '@common-types/site_manager/model/v1/node';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_LIST_PIPELINES_TYPE_FILTER } from 'src/constants/pipelines';
import {
  DEFAULT_PIPELINES_NODE_STATUS_FILTERS,
  DEFAULT_NODE_PIPELINES_STATUS_FILTERS,
} from 'src/constants/pipelineStatusFilters';
import {
  API_TAG_NODE_NAMES_LIST,
  API_TAG_NODE_PIPELINE_IDS,
  API_TAG_PIPELINE_NODE_IDS,
  nodesApiWithTag,
} from '.';

interface NodeNamesListRequest {
  nodeTypes: NodeTypes[];
  siteId: string;
  statuses?: NodeStatus[];
}

interface PipelineNodeIdsRequest {
  pipelineIds?: string[];
}

interface NodePipelineIdsRequest {
  nodeIds: string[];
}

const injectedEndpoints = nodesApiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getNodeNamesList: build.query<ListNodeNamesResponse['nodeNames'], NodeNamesListRequest>({
      query: ({ nodeTypes, siteId, statuses = [] }) => ({
        url: '/v1/node-names',
        params: {
          siteId,
          nodeTypes,
          statuses,
        },
      }),
      transformResponse: (response) => response.nodeNames,
      providesTags: [API_TAG_NODE_NAMES_LIST],
    }),

    getPipelineNodeIds: build.query<
      GetPipelineNodeIdsResponse['pipelineNodeIdsMap'],
      PipelineNodeIdsRequest
    >({
      query: ({ pipelineIds = [] }) => ({
        url: '/v1/pipeline-nodeids',
        params: {
          pipelineIds,
          types: DEFAULT_LIST_PIPELINES_TYPE_FILTER,
          statuses: DEFAULT_PIPELINES_NODE_STATUS_FILTERS,
        },
      }),
      transformResponse: (response) => response.pipelineNodeIdsMap,
      providesTags: [API_TAG_PIPELINE_NODE_IDS],
    }),

    getNodePipelineIds: build.query<
      GetNodePipelineIdsResponse['nodePipelineIds'],
      NodePipelineIdsRequest
    >({
      query: ({ nodeIds }) => ({
        url: '/v1/node-pipelineids',
        params: {
          nodeIds,
          types: DEFAULT_LIST_PIPELINES_TYPE_FILTER,
          statuses: DEFAULT_NODE_PIPELINES_STATUS_FILTERS,
        },
      }),
      transformResponse: (response) => response.nodePipelineIds,
      providesTags: [API_TAG_NODE_PIPELINE_IDS],
    }),
  }),

  overrideExisting: false,
});

export function useInvalidateNodeNamesCache() {
  const dispatch = useDispatch();

  const invalidateNodeNamesCache = useCallback(() => {
    dispatch(nodesApiWithTag.util.invalidateTags([API_TAG_NODE_NAMES_LIST]));
  }, [dispatch]);

  return invalidateNodeNamesCache;
}

export function useInvalidatePipelineNodeIdsCache() {
  const dispatch = useDispatch();

  const invalidatePipelineNodeIdsCache = useCallback(() => {
    dispatch(nodesApiWithTag.util.invalidateTags([API_TAG_PIPELINE_NODE_IDS]));
  }, [dispatch]);

  return invalidatePipelineNodeIdsCache;
}

export function useInvalidateNodePipelineIdsCache() {
  const dispatch = useDispatch();

  const invalidateNodePipelineIdsCache = useCallback(() => {
    dispatch(nodesApiWithTag.util.invalidateTags([API_TAG_NODE_PIPELINE_IDS]));
  }, [dispatch]);

  return invalidateNodePipelineIdsCache;
}

export const { useGetNodeNamesListQuery, useGetPipelineNodeIdsQuery, useGetNodePipelineIdsQuery } =
  injectedEndpoints;

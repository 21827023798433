import { useCallback, useEffect, useRef, useState } from 'react';
import { useSite } from 'src/context/SiteProvider';
import { FilterStorageType, SelectedFilterValues } from '../types';

function useFilter(
  initialFilters: SelectedFilterValues,
  viewName: string,
  storageType = FilterStorageType.SessionStorage,
  shouldUseCache: boolean | undefined = true,
) {
  const prevSiteId = useRef('');
  const { siteId } = useSite();
  const storage = storageType === FilterStorageType.LocalStorage ? localStorage : sessionStorage;
  const savedFilter = storage.getItem(viewName);

  const [selectedFilterValues, setSelectedFilterValues] = useState<SelectedFilterValues>(
    savedFilter && shouldUseCache
      ? (JSON.parse(savedFilter) as SelectedFilterValues)
      : initialFilters,
  );

  useEffect(() => {
    if (siteId && prevSiteId.current && prevSiteId.current !== siteId) {
      setSelectedFilterValues(initialFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  useEffect(() => {
    prevSiteId.current = siteId;
  }, [siteId]);

  useEffect(() => {
    storage.setItem(viewName, JSON.stringify(selectedFilterValues));
  }, [selectedFilterValues, viewName, storage]);

  const onChange = useCallback(
    (name: string, filters: string[]) => {
      setSelectedFilterValues((prev) => ({ ...prev, [name]: filters }));
    },
    [setSelectedFilterValues],
  );

  return {
    selectedFilterValues,
    setSelectedFilterValues,
    onChange,
  };
}

export default useFilter;

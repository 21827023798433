import { useCallback } from 'react';
import { usePipelineGraphContext } from 'src/context/PipelineGraphProvider';
import { useSite } from 'src/context/SiteProvider';
import { useReplacePipelineGraphMutation } from 'src/pages/Pipelines/PipelineOverview/hooks/api/useReplacePipelineGraphMutation';
import { useAddPipelineMutation } from '../api/useAddPipelineMutation';
import { useBulkCreateTransformsMutation } from '../api/useBulkCreateTransformsMutation';
import { useUpdatePipelineMutation } from '../api/useUpdatePipelineMutation';
import { usePipelineCreatePrRequestParams } from './usePipelineCreatePrRequestParams';
import { useRaisePr } from './useRaisePr';
import { updatePipelineGraphWithNewTransformIds } from './utils/pipelineGraph';
import {
  getOldTransformIdsToNewTransformIdsMap,
  getTransformRequestPayload,
} from './utils/transform';

export function usePipelineMutations() {
  const { siteId } = useSite();
  const { mutateAsync: bulkCreateTransformsMutation } = useBulkCreateTransformsMutation();
  const { mutateAsync: pipelineGraphMutation } = useReplacePipelineGraphMutation();
  const { mutateAsync: addPipelineMutation } = useAddPipelineMutation();
  const { mutateAsync: updatePipelineMutationApi } = useUpdatePipelineMutation();
  const { handleComponentMutations } = useRaisePr();
  const {
    getCreatePipelinePrRequestParams,
    getPipelinePrRequestParams,
    getUpdatePipelinePrRequestParams,
  } = usePipelineCreatePrRequestParams();

  const {
    pipelineName,
    pipelineDescription,
    pipelineComponents: { transformationItems },
    pipelineGraphMap,
    pipelineGraphInternalNodes,
    userProvidedPipelineVersionName,
  } = usePipelineGraphContext();

  const createPipeline = useCallback(async () => {
    const response = await addPipelineMutation({
      pipeline: {
        name: pipelineName,
        description: pipelineDescription,
        siteId,
      },
    });
    if (!response.data.pipeline?.id) {
      throw new Error('No pipeline ID received');
    }

    return response.data.pipeline.id;
  }, [pipelineName, pipelineDescription, addPipelineMutation, siteId]);

  const createTransforms = useCallback(
    async (pipelineId: string) => {
      const transforms = getTransformRequestPayload(transformationItems, pipelineId, siteId);
      if (transforms.length === 0) {
        return;
      }

      const response = await bulkCreateTransformsMutation(transforms);

      return getOldTransformIdsToNewTransformIdsMap(transforms, response?.data?.transform);
    },
    [bulkCreateTransformsMutation, siteId, transformationItems],
  );

  const replacePipelineGraph = useCallback(
    async (pipelineId: string, transformOldIdToNewIdMapping?: RecordString) => {
      const graphEdges = updatePipelineGraphWithNewTransformIds(
        pipelineGraphMap,
        transformOldIdToNewIdMapping,
      );

      const response = await pipelineGraphMutation({
        pipelineId,
        edges: graphEdges,
        internalNodes: pipelineGraphInternalNodes,
        versionInfo: {
          pipelineId,
          name: userProvidedPipelineVersionName,
        },
      });
      return response.data.graph;
    },
    [
      pipelineGraphMap,
      pipelineGraphMutation,
      pipelineGraphInternalNodes,
      userProvidedPipelineVersionName,
    ],
  );

  const updatePipelineGraphMutation = useCallback(
    async (pipelineId: string) =>
      await handleComponentMutations(async () => {
        const transformOldIdToNewIdMapping = await createTransforms(pipelineId);
        return await replacePipelineGraph(pipelineId, transformOldIdToNewIdMapping);
      }, getUpdatePipelinePrRequestParams(pipelineId)),
    [
      createTransforms,
      getUpdatePipelinePrRequestParams,
      handleComponentMutations,
      replacePipelineGraph,
    ],
  );

  const updatePipelineMutation = useCallback(
    async (pipelineId: string, name: string, description: string) =>
      await handleComponentMutations(
        async () =>
          await updatePipelineMutationApi({
            pipeline: { id: pipelineId, name, description, siteId },
          }),
        getPipelinePrRequestParams(pipelineId, name, description),
      ),

    [handleComponentMutations, getPipelinePrRequestParams, updatePipelineMutationApi, siteId],
  );

  const createPipelineMutation = useCallback(
    async () =>
      await handleComponentMutations(async () => {
        const pipelineId = await createPipeline();
        const transformOldIdToNewIdMapping = await createTransforms(pipelineId);
        const graph = await replacePipelineGraph(pipelineId, transformOldIdToNewIdMapping);
        return { pipelineId, graph };
      }, getCreatePipelinePrRequestParams()),
    [
      handleComponentMutations,
      getCreatePipelinePrRequestParams,
      createPipeline,
      createTransforms,
      replacePipelineGraph,
    ],
  );

  return { updatePipelineGraphMutation, createPipelineMutation, updatePipelineMutation };
}

import { ErrorLevel as ErrorLevelTypes } from '@common-types/analytics_service/conf/v1/analytics-config';
import { MetricType } from '@common-types/analytics_service/api/v1/analytics_service';
import { NodeStatus } from '@common-types/site_manager/model/v1/node';
import { DEFAULT_UI_PIPELINE_STATUS_FILTERS } from './pipelineStatusFilters';
import { DEFAULT_HYDRATION_STATUS_FILTER_VALUES } from './hydration';

export const FIXED_OPTIONS_LIST_HEIGHT_PIXELS = 400;

export enum FilterViewName {
  TimeFilter = 'timefilter',
  Dashboard = 'dashboard',
  Notifications = 'notifications',
  Destinations = 'destinations',
  Monitoring = 'monitoring',
  Pipelines = 'pipelines',
  Analytics = 'analytics',
  PipelineAnalytics = 'pipelineAnalytics',
  PipelineErrors = 'pipelineErrors',
  Sources = 'sources',
  SourceDetails = 'sourceDetails',
  Hydration = 'hydration',
  PipelineDataInsightsOverview = 'pipelineDataInsightsOverview',
  PipelineDataInsightsOverviewDate = 'pipelineDataInsightsOverviewDate',
  PipelineDataInsightsPatterns = 'pipelineDataInsightsPatterns',
  PipelineDataInsightsPatternsDate = 'pipelineDataInsightsPatternsDate',
  PullRequests = 'pullRequests',
  SiteFiles = 'siteFiles',
  Agents = 'agents',
  Configurations = 'configurations',
}

export enum DashboardFilterName {
  Pipeline = 'pipeline',
}

export enum DestinationsFilterName {
  Status = 'status',
  Type = 'type',
  Pipeline = 'pipeline',
}

export enum MonitoringFilterName {
  Pipeline = 'pipeline',
  ErrorLevel = 'errorLevel',
}

export enum PipelinesFilterName {
  Status = 'status',
  Source = 'source',
  Destination = 'destination',
}

export enum AnalyticsFilterName {
  Metric = 'metricType',
  DataPod = 'pods',
  Pipeline = 'pipeline',
  Transform = 'transform',
}

export enum PipelineErrorsFilterName {
  ErrorLevel = 'errorLevel',
}

export enum SourcesFilterName {
  Status = 'status',
  Type = 'type',
  Pipeline = 'pipeline',
}

export enum HydrationFilterName {
  Sources = 'sources',
  Destinations = 'destinations',
  Status = 'status',
}

export const DASHBOARD_DEFAULT_FILTER_VALUES = {
  [DashboardFilterName.Pipeline]: [],
};

export const DESTINATIONS_DEFAULT_FILTER_VALUES = {
  [DestinationsFilterName.Status]: [NodeStatus.NS_ACTIVE.toString()],
  [DestinationsFilterName.Type]: [],
  [DestinationsFilterName.Pipeline]: [],
};

export const MONITORING_DEFAULT_FILTER_VALUES = {
  [MonitoringFilterName.Pipeline]: [],
  [MonitoringFilterName.ErrorLevel]: Object.values(ErrorLevelTypes),
};

export const PIPELINES_DEFAULT_FILTER_VALUES = {
  [PipelinesFilterName.Status]: DEFAULT_UI_PIPELINE_STATUS_FILTERS,
  [PipelinesFilterName.Source]: [],
  [PipelinesFilterName.Destination]: [],
};

export const ANALYTICS_DEFAULT_FILTER_VALUES = {
  [AnalyticsFilterName.Metric]: [MetricType.EVENTS_BYTES],
  [AnalyticsFilterName.DataPod]: [],
  [AnalyticsFilterName.Pipeline]: [],
  [AnalyticsFilterName.Transform]: [],
};

export const PIPELINE_ERRORS_DEFAULT_FILTER_VALUES = {
  [PipelineErrorsFilterName.ErrorLevel]: Object.values(ErrorLevelTypes),
};

export const SOURCES_DEFAULT_FILTER_VALUES = {
  [SourcesFilterName.Status]: [NodeStatus.NS_ACTIVE.toString()],
  [SourcesFilterName.Type]: [],
  [SourcesFilterName.Pipeline]: [],
};

export const HYDRATION_DEFAULT_FILTER_VALUES = {
  [HydrationFilterName.Sources]: [],
  [HydrationFilterName.Destinations]: [],
  [HydrationFilterName.Status]: DEFAULT_HYDRATION_STATUS_FILTER_VALUES,
};

export const FILTER_VIEW_NAME_VS_DEFAULT_VALUES = {
  [FilterViewName.Dashboard]: DASHBOARD_DEFAULT_FILTER_VALUES,
  [FilterViewName.Destinations]: DESTINATIONS_DEFAULT_FILTER_VALUES,
  [FilterViewName.Monitoring]: MONITORING_DEFAULT_FILTER_VALUES,
  [FilterViewName.Pipelines]: PIPELINES_DEFAULT_FILTER_VALUES,
  [FilterViewName.Analytics]: ANALYTICS_DEFAULT_FILTER_VALUES,
  [FilterViewName.PipelineAnalytics]: ANALYTICS_DEFAULT_FILTER_VALUES,
  [FilterViewName.PipelineErrors]: PIPELINE_ERRORS_DEFAULT_FILTER_VALUES,
  [FilterViewName.Sources]: SOURCES_DEFAULT_FILTER_VALUES,
  [FilterViewName.Hydration]: HYDRATION_DEFAULT_FILTER_VALUES,
};

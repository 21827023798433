/* eslint-disable */
import type { Duration } from "../../../../../google/protobuf/duration";
import type { ServiceVersion } from "../../../common/metadata/service_version";
import type { Pagination, ResponseStatus, Status, TimeRange } from "../../../common/v1/common";
import type { PatternHistory, PatternSnapshot } from "../../../site_manager/model/v1/pattern";
import type {
  AcknowledgementState,
  Alert,
  AlertCategory,
  AlertEventState,
  AlertSeverity,
  AlertTemplate,
  Channel,
  ChannelType,
  ErrorLevel,
  FiringState,
  ObservoAlertEvent,
  PrometheusAlertManagerEvent,
  ReportConfig,
} from "../../conf/v1/analytics-config";
import type { EventType, StatsSummary, StreamDataAnalytics } from "./ssa";

export const protobufPackage = "v1.analytics_service";

export enum IOType {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
}

export enum MetricType {
  EVENTS_BYTES = "EVENTS_BYTES",
  EVENTS_COUNT = "EVENTS_COUNT",
}

/** a tuple to define the time series data */
export interface TimeSeriesTuple {
  unixTimeSeconds: string;
  value: number;
}

export interface TimeSeries {
  timeSeriesTuple: TimeSeriesTuple[];
}

export interface ComponentDataProcessed {
  /** Percent data optimized by this component */
  percentDataOptimized: number;
  totalInputDataBytes: number;
  totalOutputDataBytes: number;
}

export interface ErrorDetail {
  /** Type of error in vector. */
  errorName: string;
  componentId: string;
  nodeId: string;
  /** Not vector component name but component name added by user. */
  nodeName: string;
  /** Template name, for example, logstash, VPC flow log, GROK */
  templateName: string;
  /** source, sink or transform */
  nodeType: string;
  /** Valid only for transforms. */
  pipelineId: string;
  lastOccurrenceUnixTimeSeconds: string;
  /** Total occurrence, might be used in future. */
  errorCount: string;
  /** Additional information about the error. */
  errorDesc: string;
  isAcknowledged: boolean;
  errorLevel: ErrorLevel;
}

export interface AnalyticsServiceHealthRequest {
}

export interface AnalyticsServiceHealthResponse {
  status: string;
}

export interface GetDataPodListRequest {
  /** unique globally */
  siteId: string;
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
}

export interface GetDataPodListResponse {
  dataPods: string[];
}

export interface GetSiteSummaryRequest {
  /** unique globally */
  siteId: string;
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  metricType: MetricType;
  pods: string[];
}

export interface GetSiteSummaryResponse {
  /** Percent data optimized by this component */
  percentDataOptimized: number;
  processingErrors: string;
  totalInputDataBytes: number;
  totalOutputDataBytes: number;
}

export interface GetSiteComponentDataProcessedRequest {
  /** unique globally */
  siteId: string;
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  metricType: MetricType;
  pods: string[];
}

export interface GetSiteComponentDataProcessedResponse {
  componentsDataProcessedMap: { [key: string]: ComponentDataProcessed };
}

export interface GetSiteComponentDataProcessedResponse_ComponentsDataProcessedMapEntry {
  key: string;
  value: ComponentDataProcessed | undefined;
}

export interface GetSiteDataProcessedTimeSeriesRequest {
  /** unique globally */
  siteId: string;
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  resolution: Duration | undefined;
  metricType: MetricType;
  pods: string[];
}

export interface GetSiteDataProcessedTimeSeriesResponse {
  inputDataSeries: TimeSeries | undefined;
  outputDataSeries: TimeSeries | undefined;
  droppedDataSeries: TimeSeries | undefined;
}

export interface GetSiteOptimisationTimeSeriesRequest {
  /** unique globally */
  siteId: string;
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  resolution: Duration | undefined;
  metricType: MetricType;
  pods: string[];
}

export interface GetSiteOptimisationTimeSeriesResponse {
  percentDataOptimisedSeries: TimeSeries | undefined;
}

export interface GetSiteErrorTimeSeriesRequest {
  /** unique globally */
  siteId: string;
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  resolution: Duration | undefined;
  pods: string[];
}

export interface GetSiteErrorTimeSeriesResponse {
  /** Time series of sum of errors per hour */
  errorSeries: TimeSeries | undefined;
}

export interface GetSiteErrorListRequest {
  siteId: string;
  pipelineIds: string[];
  timeRange: TimeRange | undefined;
  resolution: Duration | undefined;
  pods: string[];
}

export interface GetSiteErrorListResponse {
  errorDetails: ErrorDetail[];
}

export interface GetPipelineSummaryRequest {
  /** unique globally */
  pipelineId: string;
  /** Time range for calculation */
  timeRange:
    | TimeRange
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetPipelineSummaryResponse {
  /** Percent data optimized by this component */
  percentDataOptimized: number;
  processingErrors: string;
  totalInputDataBytes: number;
  totalOutputDataBytes: number;
}

export interface PipelineSummary {
  pipelineId: string;
  percentDataOptimized: number;
  processingErrors: string;
  totalInputDataBytes: number;
  totalOutputDataBytes: number;
}

export interface GetPipelineAggregatedSummaryRequest {
  /** unique globally */
  pipelineIds: string[];
  /** Time range for analysis */
  timeRange:
    | TimeRange
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetPipelineAggregatedSummaryResponse {
  percentDataOptimized: number;
  processingErrors: string;
  totalInputDataBytes: number;
  totalOutputDataBytes: number;
}

export interface GetPipelinesSummaryListRequest {
  /** unique globally */
  pipelineIds: string[];
  /** Time range for analysis */
  timeRange:
    | TimeRange
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetPipelinesSummaryListResponse {
  pipelineSummaries: PipelineSummary[];
}

export interface GetPipelineComponentDataProcessedRequest {
  /** unique globally */
  pipelineId: string;
  /** Time range for calculation */
  timeRange:
    | TimeRange
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetPipelineComponentDataProcessedResponse {
  componentsDataProcessedMap: { [key: string]: ComponentDataProcessed };
}

export interface GetPipelineComponentDataProcessedResponse_ComponentsDataProcessedMapEntry {
  key: string;
  value: ComponentDataProcessed | undefined;
}

export interface GetPipelinesComponentDataProcessedListRequest {
  /** unique globally */
  pipelineIds: string[];
  /** Time range for calculation */
  timeRange:
    | TimeRange
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface PipelineComponentDataProcessed {
  pipelineId: string;
  componentsDataProcessedMap: { [key: string]: ComponentDataProcessed };
}

export interface PipelineComponentDataProcessed_ComponentsDataProcessedMapEntry {
  key: string;
  value: ComponentDataProcessed | undefined;
}

export interface GetPipelinesComponentDataProcessedListResponse {
  pipelinesComponentDataProcessed: PipelineComponentDataProcessed[];
}

export interface GetPipelineOptimisationTimeSeriesRequest {
  /** unique globally */
  pipelineId: string;
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  resolution:
    | Duration
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface PipelineSinkOptimisationSeries {
  sinkId: string;
  percentDataOptimisedSeries: TimeSeries | undefined;
}

export interface GetPipelineOptimisationTimeSeriesResponse {
  pipelineSinksOptimisationSeries: PipelineSinkOptimisationSeries[];
}

export interface GetPipelineDataDropTimeSeriesRequest {
  pipelineIds: string[];
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  resolution:
    | Duration
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetPipelineDataDropTimeSeriesResponse {
  dataDropSeries: TimeSeries | undefined;
}

export interface GetPipelinesOptimisationTimeSeriesListRequest {
  /** unique globally */
  pipelineIds: string[];
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  resolution:
    | Duration
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface PipelineOptimisationTimeSeries {
  pipelineId: string;
  pipelineSinksOptimisationSeries: PipelineSinkOptimisationSeries[];
}

export interface GetPipelinesOptimisationTimeSeriesListResponse {
  pipelinesOptimisationTimeseries: PipelineOptimisationTimeSeries[];
}

export interface GetPipelineDataProcessedTimeSeriesRequest {
  /** unique globally */
  pipelineId: string;
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  resolution:
    | Duration
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetPipelineDataProcessedTimeSeriesResponse {
  /** Time series of data process per hour */
  inputDataSeries: TimeSeries | undefined;
  outputDataSeries: TimeSeries | undefined;
  droppedDataSeries: TimeSeries | undefined;
}

export interface GetPipelinesDataProcessedTimeSeriesListRequest {
  /** unique globally */
  pipelineIds: string[];
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  resolution:
    | Duration
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface PipelineDataProcessedTimeSeries {
  pipelineId: string;
  inputDataSeries: TimeSeries | undefined;
  outputDataSeries: TimeSeries | undefined;
  droppedDataSeries: TimeSeries | undefined;
}

export interface GetPipelinesDataProcessedTimeSeriesListResponse {
  pipelinesDataProcessedTimeseries: PipelineDataProcessedTimeSeries[];
}

export interface GetPipelineErrorTimeSeriesRequest {
  /** unique globally */
  pipelineId: string;
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  resolution:
    | Duration
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  pods: string[];
}

export interface GetPipelineErrorTimeSeriesResponse {
  /** Time series of sum of errors per hour */
  errorSeries: TimeSeries | undefined;
}

export interface GetPipelinesErrorListRequest {
  pipelineIds: string[];
  timeRange: TimeRange | undefined;
  resolution:
    | Duration
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  pods: string[];
}

export interface PipelineErrorDetail {
  pipelineId: string;
  errorDetails: ErrorDetail[];
}

export interface GetPipelinesErrorListResponse {
  pipelinesErrorDetails: PipelineErrorDetail[];
}

export interface GetPipelinesErrorCountRequest {
  siteId: string;
  pipelineIds: string[];
  acknowledgeState: AcknowledgementState;
  timeRange: TimeRange | undefined;
}

export interface GetPipelinesErrorCountResponse {
  /** error count per pipeline */
  pipelineErrorCount: { [key: string]: string };
  /** total error count */
  totalCount: string;
}

export interface GetPipelinesErrorCountResponse_PipelineErrorCountEntry {
  key: string;
  value: string;
}

export interface AcknowledgeErrorsRequest {
  siteId: string;
  componentIds: string[];
  /** Acknowledge all errors for a given site */
  acknowledgeAll: boolean;
}

export interface AcknowledgeErrorsResponse {
}

export interface GetComponentSummaryRequest {
  /** source, sink or transformation ID, unique globally */
  nodeId: string;
  /** Time range for calculation */
  timeRange:
    | TimeRange
    | undefined;
  /** Site ID of the component */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetComponentSummaryResponse {
  /** Percent data optimized by this component */
  percentDataOptimized: number;
  processingErrors: string;
  totalInputDataBytes: number;
  totalOutputDataBytes: number;
}

export interface GetComponentSummaryListRequest {
  /** source, sink or transformation ID, unique globally */
  nodeIds: string[];
  /** Time range for calculation */
  timeRange:
    | TimeRange
    | undefined;
  /** Site ID of the component */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetComponentSummaryListResponse {
  componentsDataProcessedMap: { [key: string]: ComponentDataProcessed };
}

export interface GetComponentSummaryListResponse_ComponentsDataProcessedMapEntry {
  key: string;
  value: ComponentDataProcessed | undefined;
}

export interface GetComponentPercentDataChangedRequest {
  /** source, sink or transformation ID, unique globally */
  nodeId: string;
  /** Get input or output data */
  ioType: IOType;
  /** Time range for which we want to calculate the percentage */
  timeRange:
    | TimeRange
    | undefined;
  /** Time range to be taken as base for calculating percentage */
  baseTimeRange:
    | TimeRange
    | undefined;
  /** Site ID of the component */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetComponentPercentDataChangedResponse {
  /** Percent data changed between timeRange and baseTimeRange */
  percentDataChanged: number;
  /** Data processed in bytes in timeRange */
  totalDataProcessedBytes: number;
  /** Data processed in bytes in baseTimeRange */
  totalDataProcessedInBaseBytes: number;
}

export interface GetComponentDataProcessedRequest {
  /** source, sink or transformation ID, unique globally */
  nodeId: string;
  /** Get input or output data */
  ioType: IOType;
  /** Time range for calculation */
  timeRange:
    | TimeRange
    | undefined;
  /** Site ID of the component */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetComponentDataProcessedResponse {
  totalDataProcessedBytes: number;
}

export interface GetComponentDataProcessedTimeSeriesRequest {
  nodeId: string;
  /** Time range for calculation */
  timeRange: TimeRange | undefined;
  resolution:
    | Duration
    | undefined;
  /** Site ID of the pipeline */
  siteId: string;
  metricType: MetricType;
  pods: string[];
}

export interface GetComponentDataProcessedTimeSeriesResponse {
  /** Time series of data process per hour */
  inputDataSeries: TimeSeries | undefined;
  outputDataSeries: TimeSeries | undefined;
  droppedDataSeries: TimeSeries | undefined;
}

export interface GetComponentErrorRequest {
  /** source, sink or transformation ID, unique globally */
  nodeId: string;
  /** Time range for calculation */
  timeRange:
    | TimeRange
    | undefined;
  /** Site ID of the component */
  siteId: string;
  pods: string[];
}

export interface GetComponentErrorResponse {
  totalErrors: string;
}

export interface GetSiteVersionsRequest {
  /** Site ID of the component */
  siteId: string;
  /** Time range for calculation */
  timestamp: string | undefined;
}

export interface GetSiteVersionsResponse {
  /** Versions that existed within the specified time range. */
  versions: ServiceVersion[];
}

export interface GetDataPipelineCpuUsedTimeseriesRequest {
  siteId: string;
  timeRange: TimeRange | undefined;
  resolution: Duration | undefined;
  pods: string[];
}

export interface GetDataPipelineCpuUsedTimeseriesResponse {
  cpuUsed: TimeSeries | undefined;
}

export interface GetDataPipelineMemoryUsedTimeseriesRequest {
  siteId: string;
  timeRange: TimeRange | undefined;
  resolution: Duration | undefined;
  pods: string[];
}

export interface GetDataPipelineMemoryUsedTimeseriesResponse {
  memoryUsed: TimeSeries | undefined;
}

export interface GetSiteMonitorConfigRequest {
  siteId: string;
}

export interface GetSiteMonitorConfigResponse {
  siteId: string;
  dataPlaneMonitorConfig: { [key: string]: any } | undefined;
  controlAgentMonitorConfig: { [key: string]: any } | undefined;
  patternExtractorMonitorConfig: { [key: string]: any } | undefined;
}

/** CreateChannel request message */
export interface CreateChannelRequest {
  channel: Channel | undefined;
}

/** CreateChannel response message */
export interface CreateChannelResponse {
  channel: Channel | undefined;
}

/** UpdateChannel request message */
export interface UpdateChannelRequest {
  channel: Channel | undefined;
}

/** UpdateChannel response message */
export interface UpdateChannelResponse {
  channel: Channel | undefined;
}

/** DeleteChannel request message */
export interface DeleteChannelRequest {
  id: string;
}

/** DeleteChannel response message */
export interface DeleteChannelResponse {
  id: string;
}

/** ListChannels request message */
export interface ListChannelsRequest {
  channelIds: string[];
  channelTypes: ChannelType[];
  channelStatuses: Status[];
  pagination: Pagination | undefined;
}

/** ListChannels response message */
export interface ListChannelsResponse {
  channels: Channel[];
}

/** CreateAlert request message */
export interface CreateAlertRequest {
  alert: Alert | undefined;
}

/** CreateAlert response message */
export interface CreateAlertResponse {
  alert: Alert | undefined;
}

/** UpdateAlert request message */
export interface UpdateAlertRequest {
  alert: Alert | undefined;
}

/** UpdateAlert response message */
export interface UpdateAlertResponse {
  alert: Alert | undefined;
}

/** DeleteAlert request message */
export interface DeleteAlertRequest {
  id: string;
}

/** DeleteAlert request message */
export interface DeleteAlertResponse {
  id: string;
}

/** ListAlerts request message */
export interface ListAlertsRequest {
  siteIds: string[];
  alertIds: string[];
  templateIds: string[];
  firingStates: FiringState[];
  severities: AlertSeverity[];
  alertStatuses: Status[];
  pagination: Pagination | undefined;
}

/** ListAlerts response message */
export interface ListAlertsResponse {
  alerts: Alert[];
}

/** ListAlertTemplates request message */
export interface ListAlertTemplatesRequest {
  idsFilter: string[];
  categories: AlertCategory[];
  templateNames: string[];
  pagination: Pagination | undefined;
}

/** ListAlertTemplates response message */
export interface ListAlertTemplatesResponse {
  alertTemplates: AlertTemplate[];
}

/** ListAlertEvents request message */
export interface ListAlertEventsRequest {
  ids: string[];
  siteIds: string[];
  alertIds: string[];
  states: AlertEventState[];
  timeRange: TimeRange | undefined;
  pagination: Pagination | undefined;
}

/** ListAlertEvents response message */
export interface ListAlertEventsResponse {
  alertEvents: ObservoAlertEvent[];
  pagination: Pagination | undefined;
}

export interface GetAlertEventCountRequest {
  siteId: string;
  eventState: AlertEventState;
  acknowledgeState: AcknowledgementState;
  timeRange: TimeRange | undefined;
}

export interface GetAlertEventCountResponse {
  alertEventCount: string;
}

export interface AcknowledgeAlertEventsRequest {
  siteId: string;
  ids: string[];
  /** Acknowledge all errors for a given site */
  acknowledgeAll: boolean;
}

export interface AcknowledgeAlertEventsResponse {
}

export interface SaveStreamDataAnalyticsRequest {
  siteId: string;
  /** map<source, ssa> */
  streamDataAnalytics: { [key: string]: StreamDataAnalytics };
  generatedAt: string | undefined;
}

export interface SaveStreamDataAnalyticsRequest_StreamDataAnalyticsEntry {
  key: string;
  value: StreamDataAnalytics | undefined;
}

export interface SaveStreamDataAnalyticsResponse {
  status: ResponseStatus | undefined;
}

export interface GetStreamDataAnalyticsRequest {
  siteId: string;
  sourceId: string;
  groupBy: { [key: string]: any } | undefined;
  eventType: EventType;
}

export interface GetStreamDataAnalyticsResponse {
  siteId: string;
  sourceId: string;
  streamDataAnalytics: StreamDataAnalytics | undefined;
}

export interface GetTopMetricsStreamDataAnalyticsRequest {
  siteId: string;
  sourceId: string;
}

export interface TopMetric {
  name: string;
  occurrence: string;
}

export interface TopMetrics {
  metrics: TopMetric[];
}

export interface GetTopMetricsStreamDataAnalyticsResponse {
  siteId: string;
  sourceId: string;
  topMetrics: TopMetrics | undefined;
}

export interface GetAvailableSSAEventTypeRequest {
  siteId: string;
  sourceId: string;
}

export interface GetAvailableSSAEventTypeResponse {
  siteId: string;
  sourceId: string;
  eventType: EventType[];
}

export interface GetFieldNamesRequest {
  siteId: string;
  sourceId: string;
}

export interface GetFieldNamesResponse {
  siteId: string;
  sourceId: string;
  fieldNames: string[];
}

export interface GetVPCFlowLogsStreamDataAnalyticsSummaryRequest {
  siteId: string;
  sourceId: string;
  trafficFlowFilter: string;
}

export interface GetVPCFlowLogsStreamDataAnalyticsSummaryResponse {
  siteId: string;
  sourceId: string;
  statsSummary: StatsSummary | undefined;
  sourceAddr: { [key: string]: number };
  trafficByProtocol: { [key: string]: number };
  logStatus: { [key: string]: number };
  trafficFlow: { [key: string]: number };
  subnetId: { [key: string]: number };
  destinationAddr: { [key: string]: number };
}

export interface GetVPCFlowLogsStreamDataAnalyticsSummaryResponse_SourceAddrEntry {
  key: string;
  value: number;
}

export interface GetVPCFlowLogsStreamDataAnalyticsSummaryResponse_TrafficByProtocolEntry {
  key: string;
  value: number;
}

export interface GetVPCFlowLogsStreamDataAnalyticsSummaryResponse_LogStatusEntry {
  key: string;
  value: number;
}

export interface GetVPCFlowLogsStreamDataAnalyticsSummaryResponse_TrafficFlowEntry {
  key: string;
  value: number;
}

export interface GetVPCFlowLogsStreamDataAnalyticsSummaryResponse_SubnetIdEntry {
  key: string;
  value: number;
}

export interface GetVPCFlowLogsStreamDataAnalyticsSummaryResponse_DestinationAddrEntry {
  key: string;
  value: number;
}

export interface PushAlertEventsRequest {
  event: PrometheusAlertManagerEvent | undefined;
}

export interface PushAlertEventsResponse {
  status: ResponseStatus | undefined;
}

export interface GetPatternExtractorTagsRequest {
  siteId: string;
  sourceId: string;
  timeRange: TimeRange | undefined;
}

export interface GetPatternExtractorTagsResponse {
  tags: string[];
}

export interface GetPatternExtractorTagUniqueValuesRequest {
  siteId: string;
  sourceId: string;
  timeRange: TimeRange | undefined;
  tags: string[];
}

export interface Values {
  values: string[];
}

export interface GetPatternExtractorTagUniqueValuesResponse {
  uniqueValues: { [key: string]: Values };
}

export interface GetPatternExtractorTagUniqueValuesResponse_UniqueValuesEntry {
  key: string;
  value: Values | undefined;
}

export interface GetPatternExtractorTagAggregatesRequest {
  siteId: string;
  sourceId: string;
  timeRange: TimeRange | undefined;
  tag: string;
  tagSelector: { [key: string]: Values };
  normalizeToPercentages: boolean;
}

export interface GetPatternExtractorTagAggregatesRequest_TagSelectorEntry {
  key: string;
  value: Values | undefined;
}

export interface PatternExtractorAggregate {
  timeSeries: TimeSeries | undefined;
  sum: string;
  max: string;
  percentage: number;
}

export interface GetPatternExtractorTagAggregatesResponse {
  tagAggregates: { [key: string]: PatternExtractorAggregate };
}

export interface GetPatternExtractorTagAggregatesResponse_TagAggregatesEntry {
  key: string;
  value: PatternExtractorAggregate | undefined;
}

export interface GetPatternExtractorTaggedPatternsRequest {
  siteId: string;
  sourceId: string;
  timeRange: TimeRange | undefined;
  tagSelector: { [key: string]: Values };
  limit: number;
}

export interface GetPatternExtractorTaggedPatternsRequest_TagSelectorEntry {
  key: string;
  value: Values | undefined;
}

export interface PatternTimeSeriesInfo {
  aggregateInfo: PatternExtractorAggregate | undefined;
  patternTemplate: string;
  patternRegex: string;
}

export interface GetPatternExtractorTaggedPatternsResponse {
  patternTimeSeriesInfo: PatternTimeSeriesInfo[];
}

export interface AddPatternRequest {
  patternHistory: PatternHistory[];
}

export interface AddPatternsResponse {
  status: ResponseStatus | undefined;
}

export interface SaveSnapshotRequest {
  snapshots: PatternSnapshot[];
}

export interface SaveSnapshotResponse {
  status: ResponseStatus | undefined;
}

export interface GetPDFReportRequest {
  templateId: string;
  config: ReportConfig | undefined;
}

export interface GetPDFReportResponse {
  status: ResponseStatus | undefined;
}

export interface AnalyticsService {
  /** Get Pod list for site */
  GetDataPodList(request: GetDataPodListRequest): Promise<GetDataPodListResponse>;
  /**
   * Provides a site level summary of input/output bytes, error count and
   * percentage optimised
   */
  GetSiteSummary(request: GetSiteSummaryRequest): Promise<GetSiteSummaryResponse>;
  /**
   * Provides for all the components of a site, the input/output bytes and
   * percentage optimised
   */
  GetSiteComponentDataProcessed(
    request: GetSiteComponentDataProcessedRequest,
  ): Promise<GetSiteComponentDataProcessedResponse>;
  /**
   * Provides the per-second rate of input/output bytes processed for a site
   * calculated over given resolution window
   */
  GetSiteDataProcessedTimeSeries(
    request: GetSiteDataProcessedTimeSeriesRequest,
  ): Promise<GetSiteDataProcessedTimeSeriesResponse>;
  /**
   * Provides the per-second rate of percent optimised for a site calculated
   * over given resolution window
   */
  GetSiteOptimisationTimeSeries(
    request: GetSiteOptimisationTimeSeriesRequest,
  ): Promise<GetSiteOptimisationTimeSeriesResponse>;
  /**
   * Provides the per-second rate of processing errors for a site calculated
   * over given resolution window
   */
  GetSiteErrorTimeSeries(request: GetSiteErrorTimeSeriesRequest): Promise<GetSiteErrorTimeSeriesResponse>;
  /** Provides List of errors that have happened in a site */
  GetSiteErrorList(request: GetSiteErrorListRequest): Promise<GetSiteErrorListResponse>;
  /** Provides app_version on given time range */
  GetSiteVersions(request: GetSiteVersionsRequest): Promise<GetSiteVersionsResponse>;
  /**
   * Provides a pipeline level summary of input/output bytes, error count and
   * percentage optimised
   */
  GetPipelineSummary(request: GetPipelineSummaryRequest): Promise<GetPipelineSummaryResponse>;
  /**
   * Provides an aggregate summary of input/output bytes, error count and
   * percentage optimised for list of pipelines
   */
  GetPipelineAggregatedSummary(
    request: GetPipelineAggregatedSummaryRequest,
  ): Promise<GetPipelineAggregatedSummaryResponse>;
  /**
   * Provides a per pipeline summary of input/output bytes, error count and
   * percentage optimised for list of pipelines
   */
  GetPipelinesSummaryList(request: GetPipelinesSummaryListRequest): Promise<GetPipelinesSummaryListResponse>;
  /**
   * Provides for all the components of a pipeline, the input/output bytes and
   * percentage optimised
   */
  GetPipelineComponentDataProcessed(
    request: GetPipelineComponentDataProcessedRequest,
  ): Promise<GetPipelineComponentDataProcessedResponse>;
  /**
   * Provides for each pipeline for all the components, the input/output bytes
   * and percentage optimised for list of pipelines
   */
  GetPipelinesComponentDataProcessedList(
    request: GetPipelinesComponentDataProcessedListRequest,
  ): Promise<GetPipelinesComponentDataProcessedListResponse>;
  /**
   * Provides the per-second rate of percent optimised for a pipeline calculated
   * over given resolution window
   */
  GetPipelineOptimisationTimeSeries(
    request: GetPipelineOptimisationTimeSeriesRequest,
  ): Promise<GetPipelineOptimisationTimeSeriesResponse>;
  /**
   * Provides for each pipeline the per-second rate of percent optimised
   * calculated over given resolution window for list of pipelines
   */
  GetPipelinesOptimisationTimeSeriesList(
    request: GetPipelinesOptimisationTimeSeriesListRequest,
  ): Promise<GetPipelinesOptimisationTimeSeriesListResponse>;
  /**
   * Provides the per-second rate of input/output bytes processed for a pipeline
   * calculated over given resolution window
   */
  GetPipelineDataProcessedTimeSeries(
    request: GetPipelineDataProcessedTimeSeriesRequest,
  ): Promise<GetPipelineDataProcessedTimeSeriesResponse>;
  /**
   * Provides for each pipeline the per-second rate of input/output bytes
   * processed calculated over given resolution window for list of pipelines
   */
  GetPipelinesDataProcessedTimeSeriesList(
    request: GetPipelinesDataProcessedTimeSeriesListRequest,
  ): Promise<GetPipelinesDataProcessedTimeSeriesListResponse>;
  /**
   * Provides the per-second rate of processing errors for a pipeline calculated
   * over given resolution window
   */
  GetPipelineErrorTimeSeries(request: GetPipelineErrorTimeSeriesRequest): Promise<GetPipelineErrorTimeSeriesResponse>;
  /** Provides the per-second rate of data drop happening or not happening */
  GetPipelineDataDropTimeSeries(
    request: GetPipelineDataDropTimeSeriesRequest,
  ): Promise<GetPipelineDataDropTimeSeriesResponse>;
  /** Provides for each pipeline List of errors that have happened */
  GetPipelinesErrorList(request: GetPipelinesErrorListRequest): Promise<GetPipelinesErrorListResponse>;
  /** Get pipeline error count for a list of pipelines */
  GetPipelinesErrorCount(request: GetPipelinesErrorCountRequest): Promise<GetPipelinesErrorCountResponse>;
  /** Set acknowledgment for a list of components. */
  AcknowledgeErrors(request: AcknowledgeErrorsRequest): Promise<AcknowledgeErrorsResponse>;
  /**
   * Provides a component level summary of input/output bytes, error count and
   * percentage optimised for a given time range Test query = curl
   * "http://localhost:10000/analytics-service/v1/components/loki_sink/summary?time_range.start_timestamp.seconds=1673481600&time_range.end_timestamp.seconds=1674259200"
   */
  GetComponentSummary(request: GetComponentSummaryRequest): Promise<GetComponentSummaryResponse>;
  /**
   * Provides a per component summary of input/output bytes, error count and
   * percentage optimised for list of components
   */
  GetComponentSummaryList(request: GetComponentSummaryListRequest): Promise<GetComponentSummaryListResponse>;
  /**
   * Provides the percent data processed changed between current and previous
   * time range
   */
  GetComponentPercentDataChanged(
    request: GetComponentPercentDataChangedRequest,
  ): Promise<GetComponentPercentDataChangedResponse>;
  /**
   * Provides in bytes the amount of data processed(input or output) by a
   * component between a time range Test query -  curl
   * "http://localhost:10000/analytics-service/v1/components/loki_sink/data-processed?io_type=INPUT&time_range.start_timestamp.seconds=1673481600&time_range.end_timestamp.seconds=1674259200"
   */
  GetComponentDataProcessed(request: GetComponentDataProcessedRequest): Promise<GetComponentDataProcessedResponse>;
  /**
   * Provides the per-second rate of input/output bytes processed for a
   * component calculated over given resolution window
   */
  GetComponentDataProcessedTimeSeries(
    request: GetComponentDataProcessedTimeSeriesRequest,
  ): Promise<GetComponentDataProcessedTimeSeriesResponse>;
  /**
   * Provides number of errors in a component between a time range
   * Test query - curl
   * "http://localhost:10000/analytics-service/v1/components/loki_sink/errors?time_range.start_timestamp.seconds=1673481600&time_range.end_timestamp.seconds=1674259200"
   */
  GetComponentErrors(request: GetComponentErrorRequest): Promise<GetComponentErrorResponse>;
  /** System resources */
  GetDataPipelineCpuUsedTimeseries(
    request: GetDataPipelineCpuUsedTimeseriesRequest,
  ): Promise<GetDataPipelineCpuUsedTimeseriesResponse>;
  GetDataPipelineMemoryUsedTimeseries(
    request: GetDataPipelineMemoryUsedTimeseriesRequest,
  ): Promise<GetDataPipelineMemoryUsedTimeseriesResponse>;
  /**
   * GetStreamDataAnalytics will return directly the last stream data analytics
   * object of ssa
   */
  GetStreamDataAnalytics(request: GetStreamDataAnalyticsRequest): Promise<GetStreamDataAnalyticsResponse>;
  GetTopMetricsStreamDataAnalytics(
    request: GetTopMetricsStreamDataAnalyticsRequest,
  ): Promise<GetTopMetricsStreamDataAnalyticsResponse>;
  GetAvailableSSAEventType(request: GetAvailableSSAEventTypeRequest): Promise<GetAvailableSSAEventTypeResponse>;
  GetFieldNames(request: GetFieldNamesRequest): Promise<GetFieldNamesResponse>;
  GetVPCFlowLogsStreamDataAnalyticsSummary(
    request: GetVPCFlowLogsStreamDataAnalyticsSummaryRequest,
  ): Promise<GetVPCFlowLogsStreamDataAnalyticsSummaryResponse>;
  /**
   * SaveStreamDataAnalytics will save the stream data analytics batches in ssa
   * db it takes siteID, and a map of streamDataAnalytics with unique group by
   * key and the generation time
   */
  SaveStreamDataAnalytics(request: SaveStreamDataAnalyticsRequest): Promise<SaveStreamDataAnalyticsResponse>;
  /**
   * Given the siteId, returns the  site monitoring config for Data-plane,
   * Control-agent, and Pattern-extractor
   */
  GetSiteMonitorConfig(request: GetSiteMonitorConfigRequest): Promise<GetSiteMonitorConfigResponse>;
  /** Create a new channel */
  CreateChannel(request: CreateChannelRequest): Promise<CreateChannelResponse>;
  /** Update an existing channel */
  UpdateChannel(request: UpdateChannelRequest): Promise<UpdateChannelResponse>;
  /** Delete a channel by ID */
  DeleteChannel(request: DeleteChannelRequest): Promise<DeleteChannelResponse>;
  /** List all channels */
  ListChannels(request: ListChannelsRequest): Promise<ListChannelsResponse>;
  /** Create a new alert */
  CreateAlert(request: CreateAlertRequest): Promise<CreateAlertResponse>;
  /** Update an existing alert */
  UpdateAlert(request: UpdateAlertRequest): Promise<UpdateAlertResponse>;
  /** Delete an alert by ID */
  DeleteAlert(request: DeleteAlertRequest): Promise<DeleteAlertResponse>;
  /** List all alerts */
  ListAlerts(request: ListAlertsRequest): Promise<ListAlertsResponse>;
  /** List all alert templates */
  ListAlertTemplates(request: ListAlertTemplatesRequest): Promise<ListAlertTemplatesResponse>;
  /** Push alert events coming from prometheus to system */
  PushAlertEvents(request: PushAlertEventsRequest): Promise<PushAlertEventsResponse>;
  /** List all alert events */
  ListAlertEvents(request: ListAlertEventsRequest): Promise<ListAlertEventsResponse>;
  /** Get alert events count for given states */
  GetAlertEventCount(request: GetAlertEventCountRequest): Promise<GetAlertEventCountResponse>;
  /** Set acknowledgment for a list of alert events. */
  AcknowledgeAlertEvents(request: AcknowledgeAlertEventsRequest): Promise<AcknowledgeAlertEventsResponse>;
  /** Adds patterns for sources. */
  AddPatterns(request: AddPatternRequest): Promise<AddPatternsResponse>;
  /**
   * Persists the pattern extractor snapshot for sources.
   * The snapshot is usually a binary blob.
   */
  SavePatternSnapshot(request: SaveSnapshotRequest): Promise<SaveSnapshotResponse>;
  /** Get Pattern Extractor labels for sources */
  GetPatternExtractorTags(request: GetPatternExtractorTagsRequest): Promise<GetPatternExtractorTagsResponse>;
  /** Get Pattern Extractor tags for sources */
  GetPatternExtractorTagUniqueValues(
    request: GetPatternExtractorTagUniqueValuesRequest,
  ): Promise<GetPatternExtractorTagUniqueValuesResponse>;
  /** Get Pattern Extractor tag Aggregates for source */
  GetPatternExtractorTagAggregates(
    request: GetPatternExtractorTagAggregatesRequest,
  ): Promise<GetPatternExtractorTagAggregatesResponse>;
  /** Get Pattern Extractor label Aggregates for source */
  GetPatternExtractorTaggedPatterns(
    request: GetPatternExtractorTaggedPatternsRequest,
  ): Promise<GetPatternExtractorTaggedPatternsResponse>;
  /** Get PDF Report */
  GetPDFReport(request: GetPDFReportRequest): Promise<GetPDFReportResponse>;
}

import { DEFAULT_PAGINATION_ARGS, TEMPORARY_PAGINATION_LIMIT } from 'src/constants/queries';
import {
  AckNotificationsRequest,
  AckNotificationsResponse,
  GetUserNotificationsSummaryResponse,
  ListNotificationsResponse,
} from '@common-types/notification_service/api/v1/ns_api';
import { PaginationRequestParams } from 'src/types/PaginationRequest';
import { getApiTimestampSeconds } from 'src/utils/apiTimeRangeParams';
import apiSlice from '..';
import {
  Event_Category,
  Event_EventState,
} from '@common-types/notification_service/model/v1/event';
import { SubCategory } from '@common-types/notification_service/model/v1/event_type';
import { DEFAULT_DATE_FILTER_VALUE } from 'src/constants/dateFilter';

interface UseNotificationsQueryParams {
  siteIds: string[];
  categories?: Event_Category[];
  subCategories?: SubCategory[];
  states?: Event_EventState[];
  ackValues?: boolean[];
  severities?: string[];
  pagination?: PaginationRequestParams | undefined;
  timeValue: string;
}

interface UseNotificationsSummaryQueryParams {
  siteIds: string[];
  timeValue?: string;
}

const API_TAG_NOTIFICATION_LIST = 'notificationList';
const API_TAG_NOTIFICATION_SUMMARY = 'notificationSUMMARY';

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [API_TAG_NOTIFICATION_LIST, API_TAG_NOTIFICATION_SUMMARY],
});

const injectedEndpoints = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    notifications: build.query<ListNotificationsResponse, UseNotificationsQueryParams>({
      query: ({
        siteIds = [],
        categories = [],
        subCategories = [],
        states = [],
        ackValues = [],
        severities = [],
        pagination = DEFAULT_PAGINATION_ARGS,
        timeValue,
      }) => ({
        url: '/v1/notifications',
        params: {
          siteIds,
          categories,
          subCategories,
          states,
          ackValues,
          severities,
          ...getApiTimestampSeconds(timeValue),
          ...pagination,
          'pagination.ordering.fieldName': 'created_at',
          'pagination.ordering.descending': true,
        },
      }),
      providesTags: [API_TAG_NOTIFICATION_LIST],
    }),
    notificationsSummary: build.query<
      GetUserNotificationsSummaryResponse,
      UseNotificationsSummaryQueryParams
    >({
      query: ({ siteIds = [], timeValue = DEFAULT_DATE_FILTER_VALUE }) => ({
        url: '/v1/notifications/summary',
        params: {
          siteIds,
          ...getApiTimestampSeconds(timeValue),
          'pagination.limit': TEMPORARY_PAGINATION_LIMIT,
          'pagination.ordering.fieldName': 'created_at',
          'pagination.ordering.descending': true,
        },
      }),
      providesTags: [API_TAG_NOTIFICATION_SUMMARY],
    }),
    ackNotifications: build.mutation<AckNotificationsResponse, AckNotificationsRequest>({
      query: (data) => ({
        url: '/v1/notifications',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [API_TAG_NOTIFICATION_LIST, API_TAG_NOTIFICATION_SUMMARY],
    }),
  }),
  overrideExisting: false,
});

export const { useNotificationsQuery, useNotificationsSummaryQuery, useAckNotificationsMutation } =
  injectedEndpoints;

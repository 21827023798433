import apiSlice from 'src/store/api';

export const API_TAG_NODE_NAMES_LIST = 'nodeNamesList';
export const API_TAG_NODE_PIPELINE_IDS = 'nodePipelineIds';
export const API_TAG_PIPELINE_NODE_IDS = 'pipelineNodeIds';
export const API_TAG_SOURCES_LIST = 'sourcesList';

export const nodesApiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [API_TAG_NODE_NAMES_LIST, API_TAG_PIPELINE_NODE_IDS, API_TAG_NODE_PIPELINE_IDS],
});

export const sourcesApiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [API_TAG_SOURCES_LIST, API_TAG_NODE_NAMES_LIST],
});
